import {Getters, Mutations, Module, Actions, createMapper} from 'vuex-smart-module';

import orderApi from '@/services/order.api';

export class OrderState {
	seatInOrder;
	printLink;
	ordersList;
}

export class OrderGetters extends Getters {
	get seatInOrder() {
		return this.state.seatInOrder;
	}

	get printLink() {
		return this.state.printLink;
	}

	get ordersList() {
		return this.state.ordersList;
	}
}

export class OrderMutations extends Mutations {
	setSeatInOrder(seatInOrder) {
		this.state.seatInOrder = seatInOrder;
	}

	setPrintLink(printLink) {
		this.state.printLink = printLink;
	}

	setOrdersList(ordersList) {
		this.state.ordersList = ordersList;
	}
}

export class OrderActions extends Actions {
	async getSeatInOrder(orderId) {
		try {
			const response = await orderApi.getSeatInOrder(orderId);
			this.commit('setSeatInOrder', response);
		} catch ({message}) {
			this.commit('setSeatInOrder', []);
		}
	}

	async printOrderTicket(orderItemId) {
		return orderApi.printOrderTicket(orderItemId);
	}

	async printOrderTickets(orderId) {
		return orderApi.printOrderTickets(orderId);
	}

	async emailOrderTickets(orderId) {
		return orderApi.emailOrderTickets(orderId);
	}

	async emailOrderTicket(ticketId) {
		return orderApi.emailOrderTicket(ticketId);
	}

	async rejectOrder(orderId) {
		return orderApi.rejectOrder(orderId);
	}

	async payOrder(orderId) {
		return orderApi.payOrder(orderId);
	}

	async getOrdersList([page, size]) {
		// eslint-disable-next-line no-useless-catch
		try {
			const response = await orderApi.getOrdersList(page, size);
			this.commit('setOrdersList', response);
		} catch (error) {
			throw error;
		}
	}

	async getOrdersList2([page, size]) {
		// eslint-disable-next-line no-useless-catch
		try {
			const response = await orderApi.getOrdersList(page, size);
			return response
		} catch (error) {
			throw error;
		}
	}
}


export const orderModule = new Module({
	state: OrderState,
	getters: OrderGetters,
	mutations: OrderMutations,
	actions: OrderActions,
});

export const orderMapper = createMapper(orderModule);

export default orderModule;
