<template>
  <div class="overlay2">
    <div class="sector-view-modal">
      <div class="sector-view-modal__wrapper">
        <div style="display: flex; justify-content: space-between;">
          <p class="sector-view-modal__title">Вид из сектора {{sectorData.name.split(" ").pop()}} на площадку</p>
          <button @click="closeSectorViewModal()" style="height: 24px; border: none; background: none;">
            <svg style="width: 16px; height: 16px;"><use xlink:href="#ic_close"></use></svg>
          </button>
        </div>
          <carousel class="sector-view-modal__carousel" :per-page="1" :mouse-drag="true">
            <slide v-for="pic in sectorViewImages(sectorId)" :key="pic.id" >
              <img
                class="sector-view-modal__sector-image"
                :src="pic"
                onerror="javascript:this.src='/resources/sectorsview/logoHCSochi.svg'"
                alt=""
              />
            </slide>
          </carousel>
        <!-- <div class="sector-view-modal__sector-image">
          <img
            class="sector-view-modal__sector-img"
            onerror="javascript:this.src='/resources/sectorsview/logoHCSochi.svg'" :src="'/resources/sectorsview/' + sectorId + '.jpg'" alt=""/>
        </div> -->
        <div class="list">
          <p class="sector-view-modal__subtitle">Осталось свободных мест: {{ sectorData.freeSeats }}</p>
          <ul class="sector-view-modal__list">
            <li class="sector-view-modal__list-item" :key="index" v-for="(price, index) in sectorData.availablePrices">Стоимость: {{ price }} ₽</li>
          </ul>
        </div>
        <div class="sector-view-modal__actions">
          <button class="yellow-button" @click="closeSectorViewModal()">Выбрать билеты</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import sectorsViews from '@/mocks/sectorsViews.json';

export default {
  name: 'SectorViewModal',
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      sectorsViews: [],
      sectorImages : [],
    }
  },
  computed: {
  },
  methods: {
    closeSectorViewModal() {
      this.$emit('closeSectorViewModal', false);
    },

    sectorViewImages(sectorId) {
      const x = sectorsViews.find((item) => +item.id === +sectorId);
      if (x && x.img && x.img.length) {
        this.sectorImages = x.img;
      } else {
        this.sectorImages = ['/resources/sectorsview/logoHCSochi.svg'];
      }
      return this.sectorImages;
    },

  },
  props: { 
    sectorId : { type: Number},
    sectorData: { type: Object },
  }
}
</script>

<style lang="scss" src="@/styles/SectorViewModal.scss">
</style>
