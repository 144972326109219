<template>
  <footer class="sochi-footer">
        <div class="ft-wrap">
            <div class="sochi-footer__item">
                <div class="sochi-footer__wrap1">
                    <a href="">
                        <!-- <img src="https://tickets.hcsochi.ru/static/tickets/img/footer.png"> -->
                        <!-- <img :src="require('@/assets/images/footer-logo.png')" /> -->
                        <img :src="require('@/assets/images/sochi-new-wb-2024.svg')" />
                    </a>
                    <div class="sochi-footer__block">
                        <div class="sochi-footer__title">
                            Хоккейный клуб «Сочи»
                        </div>
                        <div class="sochi-footer__text">
                            При использовании материалов <br>
                            сайта ссылка обязательна<br>
                            Copyright © 2014 - 2024<br>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sochi-footer__item">
                <div class="sochi-footer__title">
                    Нужна помощь билетного отдела?
                </div>
                <div class="sochi-footer__text">
                    Напишите нам: tickets@hcsochi.ru<br>
                    <!-- Телефон: +7 928 455 3332 -->
                    <!--<a href="">Правила приобретения</a>-->
                </div>
            </div>
            <div class="sochi-footer__item">
                <div class="sochi-footer__text">
                    Билетная система - ООО «ИнфоТех» ®
                    <a href="https://tickets.hcsochi.ru/resources/files/policy_protect.pdf" target="_blank">
                        Политика по защите, обработке<br>и хранению персональных данных
                    </a>
                    <a href="https://tickets.hcsochi.ru/resources/files/tickets_back.pdf" target="_blank">
                        Заявление на возврат билетов
                    </a>
                </div>
            </div>
        </div>
    </footer>
</template>   

<script>
export default {
  name: 'Footer',
}
</script>

<style lang="scss" src="@/styles/Footer.scss">
</style>
