import axios from 'axios';

export default {
	async getSeatInOrder(orderId) {
		try {
			const url = `/webapi/orders/${orderId}/seats`;
			const response = await axios.get(url);
			return response.data;
		} catch {
			throw new Error('Failed to get seat in order. Please try again later.');
		}
	},
	async printOrderTicket(orderItemId) {
		try {
			const url = `/webapi/tickets/pdf/print/ticket/${orderItemId}`;
			const response = await axios.post(url, null, {
				responseType: 'blob'
			});

			return response.data;
		} catch (error) {
			const response = JSON.parse(await error.response.data.text());
			throw new Error(response.errorMessage);
		}
	},

	async printOrderTickets(orderId) {
		try {
			const url = `/webapi/tickets/pdf/print/order/${orderId}`;
			const response = await axios.post(url, null, {
				responseType: 'blob'
			});

			return response.data;
		} catch (error) {
			const response = JSON.parse(await error.response.data.text());
			throw new Error(response.errorMessage);
		}
	},

	async emailOrderTickets(orderId) {
		try {
			const url = `/webapi/tickets/pdf/email/order/${orderId}`;
			const response = await axios.post(url);

			return response.data;
    } catch (error) {
		throw new Error(error.response.data.errorMessage);
    }
	},

	async emailOrderTicket(ticketId) {
		try {
			const url = `/webapi/tickets/pdf/email/ticket/${ticketId}`;
			const response = await axios.post(url);

			return response.data;
    } catch (error) {
		throw new Error(error.response.data.errorMessage);
    }
	},

	async rejectOrder(orderId) {
		try {
			const url = `/webapi/orders/${orderId}/reject`;
			const response = await axios.put(url);

			return response.data;
    } catch (error) {
		throw new Error(error.response.data.errorMessage);
    }
	},

	async payOrder(orderId) {
		try {
			const model = {};
			const url = `/webapi/orders/${orderId}/payment/process`;
			const response = await axios.put(url, model);
			return response.data;
    } catch (error) {
		throw new Error(error.response.data.errorMessage);
    }
	},

	async getOrdersList(page, size) {
		try {
			const url = `/webapi/orders/list/upcoming?page=${page}&size=${size}`;
			const response = await axios.post(url);
			return response.data;
    } catch (error) {
      throw error.response;
    }
	},
}
