<template>
  <div class="order-item">
    <div
      v-for="orderItem in slicedOrder"
      :key="orderItem.id"
      class="order-item__wrap"
      :style="order.statusId === 99 && layerStyles"
    >
      <div class="order-item__header">
        <div class="order-item__main-info-section">
          <div class="order-item__info-item">
            <span style="min-width: 77px;" class="order-item__info-item-text">{{ order.id }}</span>
          </div>
          <div class="order-item__info-item">
            <span style="min-width: 120px;" class="order-item__info-item-text">{{ order.dateTime }}</span>
          </div>
          <div class="order-item__info-item">
            <span style="min-width: 210px;" class="order-item__info-item-text">{{ orderItem.actionTypesWeb.length > 0 ? orderItem.actionTypesWeb[0].name : ''}}</span>
          </div>
        </div>
        <div v-if="orderItem.algorithmSaleId != 520 && orderItem.algorithmSaleId != 522 && orderItem.algorithmSaleId != 535 && orderItem.algorithmSaleId != 526 && orderItem.algorithmSaleId != 530 && orderItem.algorithmSaleId != 540" class="order-item__date-section">
          <div class="order-item__info-item">
            <span class="order-item__info-item-text">{{ `${moment([orderItem.actionDay + ' ' + orderItem.actionTime], "DD.MM.YYYY").locale('ru').format('DD MMMM YYYY, dd')}` }}</span>
          </div>
        </div>
        <div class="order-item__status-section">
          <div class="order-item__info-item">
            <span class="order-item__info-item-text">{{ order.statusName }}</span>
          </div>
        </div>
      </div>
      <div v-if="orderItem.algorithmSaleId != 520 && orderItem.algorithmSaleId != 522 && orderItem.algorithmSaleId != 535 && orderItem.algorithmSaleId != 526 && orderItem.algorithmSaleId != 530 && orderItem.algorithmSaleId != 540" class="order-item__date-section-mobile">
        <div class="order-item__info-item">
          <span class="order-item__info-item-text">{{ `${moment([orderItem.actionDay + ' ' + orderItem.actionTime], "DD.MM.YYYY").locale('ru').format('DD MMMM YYYY, dd')}` }}</span>
        </div>
      </div>
      <div class="order-item__header-mobile">
        <div class="order-item__header-info-item-mobile">
          <span class="order-item__info-item-mobile-title">№ Заказа</span>
          <span  class="order-item__info-item-mobile-value">{{ order.id }}</span>
        </div> 
        <div class="order-item__header-info-item-mobile">
          <span class="order-item__info-item-mobile-title">Дата заказа</span>
          <span  class="order-item__info-item-mobile-value">{{ order.dateTime }}</span>
        </div> 
        <div class="order-item__header-info-item-mobile">
          <span class="order-item__info-item-mobile-title">Мероприятие</span>
          <span  class="order-item__info-item-mobile-value">{{ orderItem.actionTypesWeb.length > 0 ? orderItem.actionTypesWeb[0].name :'' }}</span>
        </div> 
        <div class="order-item__header-info-item-mobile">
          <span class="order-item__info-item-mobile-title">Статус заказа</span>
          <span  class="order-item__info-item-mobile-value">{{ order.statusName }}</span>
        </div> 
      </div>
      <div class="order-item__content">
        <div v-if="orderItem.algorithmSaleId != 520 && orderItem.algorithmSaleId != 522 && orderItem.algorithmSaleId != 535 && orderItem.algorithmSaleId != 526 && orderItem.algorithmSaleId != 530 && orderItem.algorithmSaleId != 540" class="order-item__date-content-mobile">
          <div class="order-item__info-item">
            <span class="order-item__info-item-text">{{ `${moment([orderItem.actionDay + ' ' + orderItem.actionTime], "DD.MM.YYYY").locale('ru').format('DD MMMM YYYY, dd')}` }}</span>
          </div>
        </div>
        <div v-if="orderItem.guestTeamLogoLink && (orderItem.homeTeamName.length + orderItem.guestTeamName.length < 17)" class="order-item__game-title">
          <div class="order-item__team-name">
            <img class="order-item__team-img" :src="orderItem.homeTeamLogoLink" />
            <span class="order-item__team-title">{{ orderItem.homeTeamName }}</span>
          </div>
          <img class="order-item__team-separator-mobile" :src="require('@/assets/images/team-separator.png')" />
          <!-- <span style="color: black;" class="order-item__team-separator">{{ ` — ` }}</span> -->
          <div class="order-item__team-name">
            <img class="order-item__team-img" :src="orderItem.guestTeamLogoLink" />
            <span class="order-item__team-title">{{ orderItem.guestTeamName }}</span>
          </div>
        </div>
        <div v-else-if="orderItem.guestTeamLogoLink && (orderItem.homeTeamName.length + orderItem.guestTeamName.length >= 17)" class="order-item__game-title-big">
          <div class="order-item__team-name">
            <img class="order-item__team-img" :src="orderItem.homeTeamLogoLink" />
            <span class="order-item__team-title">{{ orderItem.homeTeamName }}</span>
          </div>
          <img class="order-item__team-separator-mobile" :src="require('@/assets/images/team-separator.png')" />
          <!-- <span class="order-item__team-separator">{{ ` — ` }}</span> -->
          <div class="order-item__team-name">
            <img class="order-item__team-img" :src="orderItem.guestTeamLogoLink" />
            <span class="order-item__team-title">{{ orderItem.guestTeamName }}</span>
          </div>
        </div>
        <div v-else class="order-item__game-title">
          <div class="order-item__team-full-name">
            <span class="order-item__team-title">{{ `${orderItem.actionFullName}` }}</span>
          </div>
        </div>
        <div class="order-item__game-info-wrap">
          <div v-if="orderItem.algorithmSaleId !== 516" class="order-item__game-info">
            <div class="order-item__info-item">
              <span class="order-item__info-item-title">Сектор</span>
              <span  class="order-item__info-item-value">{{ orderItem.sectorName }}</span>
            </div>  
            <div class="order-item__info-item">
              <span class="order-item__info-item-title">Ряд</span>
              <span  class="order-item__info-item-value">{{ orderItem.rowName }}</span>
            </div>
            <img :src="require('@/assets/images/ellipsis.png')"/>
            <div class="order-item__info-item">
              <span class="order-item__info-item-title">Место</span>
              <span  class="order-item__info-item-value">{{ orderItem.seatName }}</span>
            </div>  
          </div>
          <div v-else class="order-item__game-info">
            <div class="order-item__info-item">
              <span class="order-item__info-item-title">Место</span>
              <span  class="order-item__info-item-value">{{ orderItem.sectorName }}</span>
            </div>
            <div class="order-item__info-item">
              <span class="order-item__info-item-title">Кол-во</span>
              <span  class="order-item__info-item-value">{{ orderItem.seatQuantity }} шт.</span>
            </div>
          </div>
          <div class="order-item__price-info">
            <div class="order-item__info-item">
              <span class="order-item__info-item-title">Стоимость</span>
              <span  class="order-item__info-item-value">{{`${orderItem.ticketPrice*orderItem.seatQuantity} ₽`}}</span>
            </div>  
          </div>
        </div>
      </div>
      <div class="order-item__footer">
        <div class="order-item__ticket-actions">
          
          <div v-if="(order.statusId === 40 || order.statusId === 30)  && isLoading" class="order-item__ticket-action" style="opacity: 0.3; cursor:default;">
            <img class="order-item__action-img" :src="require('@/assets/images/printer.png')" />
            <span class="order-item__action-text">Скачать</span>
          </div>
          <button v-if="(order.statusId === 40 || order.statusId === 30) && !isLoading && orderItem.ticketId" class="order-item__ticket-action" @click="printTicket(orderItem.ticketId)"
          >
            <img class="order-item__action-img" :src="require('@/assets/images/printer.png')" />
            <span class="order-item__action-text">Скачать</span>
          </button>

          <div v-if="(order.statusId === 40 || order.statusId === 30)  && isLoading" class="order-item__ticket-action" style="opacity: 0.3; cursor:default;">
            <img class="order-item__action-img" :src="require('@/assets/images/mail.png')"/>
            <span class="order-item__action-text">Отправить на почту</span>
          </div>
          <button v-if="(order.statusId === 40 || order.statusId === 30)  && !isLoading && orderItem.ticketId" class="order-item__ticket-action" @click="emailTicket(orderItem.ticketId)"
          >
            <img class="order-item__action-img" :src="require('@/assets/images/mail.png')"/>
            <span class="order-item__action-text">Отправить на почту</span>
          </button>

          <div v-if="(order.statusId === 40 || order.statusId === 30)  && isLoading" class="order-item__ticket-action" style="opacity: 0.3; cursor:default;">
            <img class="order-item__action-img" :src="require('@/assets/images/mail.png')"/>
            <span class="order-item__action-text">Показать QR</span>
          </div>
          <button v-if="(order.statusId === 40 || order.statusId === 30)  && !isLoading && orderItem.ticketId" class="order-item__ticket-action" @click="qrTicket(orderItem)"
          >
            <img class="order-item__action-img" :src="require('@/assets/images/mail.png')"/>
            <span class="order-item__action-text">Показать QR</span>
          </button>


          <div v-if="(order.statusId === 40 && order.statusId === 30)  && !isLoading && !orderItem.ticketId" class="order-item__ticket-action" style="opacity: 0.3; cursor:default;">
            <span class="order-item__action-text">Возвращен</span>
          </div>
        </div>
        
        <div class="order-item__info-item-status-mobile">
            <span class="order-item__info-item-text">{{ order.statusName }}</span>
          </div>
        <button v-if="(order.payStatusId === 10 || order.payStatusId === 20) && order.statusId !== 99" class="order-item__ticket-action"
          @click="rejectOrderId(order)"  
        >
          <span v-if="!isRejectLoading" class="order-item__return-ticket-action-text">Отменить заказ</span>
          <span v-if="isRejectLoading" style="min-width: 140px;">
            <half-circle-spinner  style="margin-left: auto; margin-right: auto;"
              :animation-duration="1000"
              :size="20"
              :color="'gray'"
            />
          </span>
        </button>
        <button v-if="(order.payStatusId === 10 || order.payStatusId === 20) && order.statusId !== 99" class="order-item__ticket-action"
          @click="payOrderId(order.id)"  
        >
          <span v-if="!isContentLoading" class="order-item__return-ticket-action-text">Оплатить заказ</span>
          <span v-if="isContentLoading" style="min-width: 140px;">
            <half-circle-spinner  style="margin-left: auto; margin-right: auto;"
              :animation-duration="1000"
              :size="20"
              :color="'gray'"
            />
          </span>
        </button>
        
      </div>
    </div>
    <div v-if="order.seats.length > 1" class="order-item__more-orders-wrap" @click="isShowOrders = !isShowOrders" >
      <div class="order-item__more-orders">
        <img v-if="isShowOrders" :src="require('@/assets/images/arrow-top.png')" />
        <span v-else class="order-item__more-orders-number">{{ `+${order.seats.length - 1}` }}</span>
      </div>
    </div>
    <ErrorPopup
      v-if="isErrorPopupShown"
      @closeErrorPopup="closeErrorPopup"
      :errorMessage="errorMessage"
      :isAuthorizationError="false"
    /> 
    <SuccessModal 
          v-if="isSuccessModalShown"
          @closeModal="closeModal"
        />
    <TicketViewModal
          v-if="isShowTicketViewModal"
          @closeTicketModal="closeTicketModal"
          :ticket = this.ticketView
        />
    <AuthorizationModal 
      v-if="isAuthorizationModalShown"
      @closeAuthorizationModal="closeAuthorizationModal" 
    />
    
  </div>
</template>

<script>
import { orderMapper } from '@/store/modules/order';
import TicketViewModal from '../modals/TicketViewModal.vue';
// import * as printJS from 'print-js';
import ErrorPopup from '../ErrorPopup.vue';
import SuccessModal from '../modals/SuccessModal.vue';
import AuthorizationModal from "@/components/header/AuthorizationModal.vue";
import {authorizationMapper} from "@/store/modules/authorization";
import * as $ from 'jquery';
import {HalfCircleSpinner} from 'epic-spinners';


export default {
  name: 'OrderItem',
  props: {
    order: { type: Object }
  },
  data() {
    return {
      localOrder: this.order,
      isShowOrders: false,
      errorMessage: '',
      isErrorPopupShown: false,
      isLoading: false,
      isSuccessModalShown: false,
      isAuthorizationModalShown: false,
      isContentLoading: false,
      isRejectLoading: false,
      isShowTicketViewModal: false,
      ticketView: {},
    }
  },
  components: {
    ErrorPopup,
    SuccessModal,
    AuthorizationModal,
    HalfCircleSpinner,
    TicketViewModal,
  },
  async mounted() {
  },
  computed: {
    ...authorizationMapper.mapGetters(['userInfo']),
    slicedOrder() {
      return this.isShowOrders ? this.localOrder.seats : this.localOrder.seats.slice(0, 1);
    },
    layerStyles() {
      return {
        "opacity": '0.2',
        "pointer-events": "none",
      }
    },
  },
  methods: {
    ...orderMapper.mapActions(['getSeatInOrder', 'printOrderTicket', 'emailOrderTicket', 'rejectOrder', 'payOrder', 'getOrdersList']),
    
    closeErrorPopup(isShowModal) {
      this.isErrorPopupShown = isShowModal;
    },
    closeAuthorizationModal(isShowModal) {
      this.isAuthorizationModalShown = isShowModal;
      const wrapTrash = $('.subheader');
      wrapTrash.removeClass('hide');
    },
    closeModal(isShowModal) {
      this.isSuccessModalShown = isShowModal;
    },
    closeTicketModal(isShowTicketModal) {
      this.isShowTicketViewModal = isShowTicketModal;
    },
    isSafari() {
        return Object.prototype.toString.call((window).HTMLElement).indexOf('Constructor') > 0 ||
            navigator.userAgent.toLowerCase().indexOf('safari') !== -1;
    },
    async printTicket(ticketId) {

      const authorizedUserEmail = this.userInfo.userEmail;

      if (!authorizedUserEmail) {
        const wrapTrash = $('.subheader');
        wrapTrash.addClass('hide');
        this.isAuthorizationModalShown = true;
        return;
      } else {
        if (!this.isLoading && ticketId) {
         try {
            this.isLoading = true;
            const pdf = await this.printOrderTicket(ticketId);
            const pdfUrl = URL.createObjectURL(pdf);
            // if (this.isSafari()) {
              const a = document.createElement('a');
              a.title = 'HC Sochi ticket';
              // document.body.appendChild(a);
              // a.style.display = 'none';
              // a.setAttribute('target', '_blank');
              a.href = pdfUrl;
              // a.click();
              // document.body.removeChild(a);
              a.download = 'ticketId '+ ticketId + '.pdf';
              
              a.dispatchEvent(new MouseEvent('click'));
            // } else {
            //   setTimeout(() => {
            //     printJS({printable: pdfUrl, type: 'pdf'});
            //   }, 500);
            // }
            this.isLoading = false;
          } catch (error) {
            this.errorMessage = error.message;
            this.isErrorPopupShown = true;
            this.isLoading = false;
          }
        }
      }
    },
    async emailTicket(ticketId) {

      const authorizedUserEmail = this.userInfo.userEmail;

      if (!authorizedUserEmail) {
        const wrapTrash = $('.subheader');
        wrapTrash.addClass('hide');
        this.isAuthorizationModalShown = true;
        return;
      } else {
        if (!this.isLoading && ticketId) {
          try {
            this.isLoading = true;
            await this.emailOrderTicket(ticketId);
            this.isSuccessModalShown = true;
            this.isLoading = false;
          } catch (error) {
            this.errorMessage = error.message;
            this.isErrorPopupShown = true;
            this.isLoading = false;
          }
        }
      }
    },

    async qrTicket(ticket) {
      this.ticketView = ticket;
      this.isShowTicketViewModal = true;
    },
    
    async rejectOrderId(order) {

      const authorizedUserEmail = this.userInfo.userEmail;

      if (!authorizedUserEmail) {
        const wrapTrash = $('.subheader');
        wrapTrash.addClass('hide');
        this.isAuthorizationModalShown = true;
        return;
      } else {
        if (!this.isLoading) {
          this.isRejectLoading = true;
          try {
            this.isLoading = true;
            await this.rejectOrder(order.id);
            order.statusId = 99;
            order.payStatusId = 10;
            order.statusName = 'Отмененные заказы';
            this.isLoading = false;
            this.isRejectLoading = true;
          } catch (error) {
            this.errorMessage = error.message;
            this.isErrorPopupShown = true;
            this.isLoading = false;
            this.isRejectLoading = false;
          }
        }
      }
    },

    async payOrderId(orderId) {
      
      const authorizedUserEmail = this.userInfo.userEmail;

      if (!authorizedUserEmail) {
        const wrapTrash = $('.subheader');
        wrapTrash.addClass('hide');
        this.isAuthorizationModalShown = true;
        return;
      } else {
        if (!this.isLoading) {
          this.isContentLoading = true; 
          try {
            this.isLoading = true;
            const response = await this.payOrder(orderId);
            if (response.redirectUrl) {
              this.isLoading = false;
              window.location.href = response.redirectUrl;
            }
            this.isLoading = false;
            this.isContentLoading = false; 
          } catch (error) {
            this.errorMessage = error.message;
            this.isErrorPopupShown = true;
            this.isLoading = false;
            this.isContentLoading = false; 
          }
        }
      }
    },
  },
}
</script>

<style lang="scss" src="@/styles/Orders.scss">
</style>
