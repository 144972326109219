<template>
  <section class="abonements-main__header">
    <div class="abonements-main__banner">
      <!-- <img src="" alt="" class="abonements-main__banner-image"> -->
    </div>
    <div>
      <h1 class="abonements-main__title abonements-main__title--header">Абонементы на сезон 2024/25</h1>
      <p>
        Открыта продажа абонементов на все домашние матчи ХК "Сочи" в сезоне 24/25, включающие 34 игры в регулярном чемпионате КХЛ + матчи серии плей-офф
        <!-- <br/> 
        Полный абонемент включает в себя: -->
      </p>
      <!-- <ul class="abonements-main__list">
        <li>Все домашние матчи «ХК Сочи» в регулярном чемпионате КХЛ</li>
        <li>Все домашние матчи 28-го турнира по хоккею памяти И. Х. Ромазана</li>
        <li>Все домашние матчи МХК «ХК Сочи»</li>
      </ul> -->
      <div class="abonements-main__actions">
        <button class="abonements-main__button yellow-button" type="button" @click="scrollToKinds">Выбрать абонемент</button>
        <!-- <a class="abonements-main__link" href="https://hcsochi.ru/tickets/abonements">
          <svg>
            <use xlink:href="#ic_pdf"></use>
          </svg>
          <span>
            Абонементная программа 2024-2025
          </span>
        </a> -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AbonementsBanner',
  methods: {
    scrollToKinds() {
      this.$emit('scrollToKinds');
    }
  },
}
</script>

<style lang="scss" src="@/styles/Abonements.scss">
</style>
