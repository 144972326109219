<template>
<!--  <router-link :to="{ name: 'sector', params: { calendarId: data.calendarId, sectorId: data.sectorId}, query: { seasonIds: seasonIds} }">-->
  <div v-on:click="goToSector()" :class="{pointer: data.seatClassId !== 0}">
    <AuthorizationModal
        v-if="isAuthorizationModalShown"
        @closeAuthorizationModal="closeAuthorizationModal"
    />
    <div class="tickets-system-local__scheam-bubble" v-if="data"
          @mouseleave="$emit('mouseleave')"
          @mouseover="$emit('mouseover')"
          :style="{bottom: data.top + 'px', left: data.left + 'px', transform: 'translate(-50%, ' + (this.direction === 'top' ? '0%' : '100%') + ')'}">
      <div class="inner">
        <!-- <router-link :to="{ name: 'sector', params: { calendarId: data.calendarId, sectorId: data.sectorId}, query: { seasonIds: seasonIds} }"> -->
          <p class="heading-sector">{{ data.name }}</p>
        <!-- </router-link> -->
        <p v-if="data.sectorId !== 19650 && data.sectorId !== 19640 && data.sectorId !== 19594 && data.sectorId !== 19606 && data.seatClassId !== 0" class="heading">Вид из сектора на площадку</p>
        <div v-if="data.sectorId !== 19650 && data.sectorId !== 19640 && data.sectorId !== 19594 && data.sectorId !== 19606 && data.seatClassId !== 0" class="picture-sector">
          <img
            onerror="javascript:this.src='/resources/sectorsview/logoHCSochi.svg'" :src="'/resources/sectorsview/stadium/' + data.sectorId + '_1.jpg'" alt=""/>
        </div>
        <div class="list">
          <p v-if="data.sectorId === 19650 || data.sectorId === 19640">Гости наблюдают за хоккейным матчем, удобно расположившись на местах в центральном секторе арены. Помимо лучшего вида на площадку, нашим гостям предложено кейтеринговое обслуживание и высочайший уровень гостеприимства.</p>
          <p v-if="data.sectorId === 19594 || data.sectorId === 19606">VIP-ложа. Уютное пространство с отдельным входом, великолепным обзором, ресторанным обслуживанием и высоким качеством сервиса. Количество: до 10 человек.</p>
          <p class="list-heading">Осталось свободных мест: {{ data.freeSeats }}</p>
          <ul class="list-inner">
            <li class="list-item" :key="index" v-for="(price, index) in data.availablePrices">Стоимость: {{ price }} ₽</li>
          </ul>
        </div>
        <!-- <router-link :to="{ name: 'sector', params: { calendarId: data.calendarId, sectorId: data.sectorId}, query: { seasonIds: seasonIds} }"> -->
          <button v-if="data.seatClassId !== 0" type="button" class="yellow-button">Выбрать билеты</button>
        <div v-if="data.seatClassId === 0 && !textError && !this.reservedZone" style="display: flex;justify-content: center;align-content: center;align-items: center;">
          <button type="button" class="yellow-button" style="min-width: 60px" @click="removeQuant()">-</button>
          <div style="padding: 0 10px;margin-top: 16px">{{quant}}</div>
          <button type="button" class="yellow-button" style="min-width: 60px" :disabled="quant === data.freeSeats" @click="addQuant()">+</button>
        </div>
        <div v-if="data.seatClassId === 0 && !textError && this.reservedZone"
        style="font-size: 14px;line-height: 22px; padding-top: 16px">
          Для изменения количества мест небходимо удалить резерв из корзины.
        </div>

        <button v-if="data.seatClassId === 0 && !textError && !this.reservedZone" type="button" class="yellow-button" style="margin-left: auto;margin-right: auto" :disabled="quant === 0" @click="clickReserveSeat()">{{this.actionName}}</button>
        <!-- </router-link> -->
        <form type="submit" method="GET" action="/webapi/auth/login" v-if="data.seatClassId === 0 && textError">
          <button
              class="yellow-button"
              style="min-width: 200px; margin-right: auto; margin-left: auto;"
              :disabled="isLoading"
              :class="{'loading': isLoading}"
          >
            <span class="authorization-modal__come-in-title">Войти</span>
            <span class="loader"></span>
          </button>
        </form>
      </div>  
    </div>
    <ErrorPopup
        v-if="isErrorPopupShown"
        @closeErrorPopup="closeErrorPopup"
        :errorMessage="errorMessage"
    />
  </div>
<!-- </router-link>-->
</template>


<script>
// 12 line
// 
import router from "@/router";
import {calendarMapper} from "@/store/modules/calendar";
import ErrorPopup from "@/components/content/ErrorPopup.vue";
import AuthorizationModal from "@/components/header/AuthorizationModal.vue";


export default {
  name: 'SectorInfoLayer',
  components: {ErrorPopup, AuthorizationModal},
  data() {
    return {
      direction: 'top',
      quant: 0,
      actionName: 'В корзину',
      isAuthorizationModalShown: false,
      textError: false,
      isErrorPopupShown: false,
      errorMessage: '',
      onAuthModalClose: null,
      isLoading: false,
      ssss: [],
    }
  },
  props: {
    data: {
      type: Object
    },
    seasonIds: {
      type: Array
    },
  },
  mounted() {
    const bbox = this.$el.getBoundingClientRect();
    if (bbox.top <= 0) {
      this.direction = 'bottom';
    }
  },
  computed: {
    ...calendarMapper.mapGetters(['reservedSeats']),
    reservedZone() {
      return !!this.reservedSeats.find(obj => {
        return obj.id === this.data.sectorId && obj.calendarId === +this.data.calendarId
      });
    }
  },
  methods: {
    ...calendarMapper.mapActions(['getReservedSeatsList','reserveSeat',]),
    closeAuthorizationModal(isShowModal, authorized) {
      if(typeof this.onAuthModalClose === 'function') {
        this.onAuthModalClose.call(this, authorized);
      }
      this.onAuthModalClose = null;
      this.isAuthorizationModalShown = isShowModal;
    },
    closeErrorPopup(isShowModal) {
      this.isErrorPopupShown = isShowModal;
    },
    goToSector() {
      if (this.data.seatClassId !== 0)  {
        router.push({name: 'sector', params: { calendarId: this.data.calendarId, sectorId: this.data.sectorId}, query: { seasonIds: this.seasonIds} })
      }
    },
    addQuant() {
      if(this.data.freeSeats !== 0 && this.quant < this.data.freeSeats)  {
        this.quant += 1;
      }
    },
    removeQuant() {
      if (this.quant > 0)  {
        this.quant -= 1;
      }
    },
   async clickReserveSeat() {
      if (!this.textError) {
        const reserveInfo = {
          "quantity": this.quant,
          "seasonIds": this.seasonIds,
          "seatId": this.data.sectorId,
          //  "categoryId": 1,
        };
        try {
          await this.reserveSeat({calendarId: this.data.calendarId, reserveInfo});

        } catch (e) {
          if (e.status === 401) {
            this.textError = true;
          } else {
            this.textError = true;
          }
        }
      } else {
        this.textError = true;
      }
    },

  }
}
</script>

<style scoped lang="scss" src="@/styles/SectorInfoLayer.scss">
</style>
