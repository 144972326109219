
import {Module, Actions, createMapper} from 'vuex-smart-module';


export class AnalyticActions extends Actions {
	async pushYaMetrikPurchase(seats) {
		const promocode = seats?.promoCode;
		const dataLayer = window.dataLayer || [];
		if (promocode) {
		dataLayer.push({
			"ecommerce": {
				"currencyCode": "RUB",
				"purchase": {
					"actionField": {
						"id" : seats?.orderId,
						"coupon": promocode
					},
					"products": 
						seats.orderSeats.map((seat, index) => ({
							id: seat.ticketId,
							name: seat.calendarShortName + ' ' + (seat?.calendarDay ? seat?.calendarDay : ''),
							price: seat.price,
							quantity: seat.quantity,
							position: index + 1
						}))
				}
			}});
		} else {
			dataLayer.push({
				"ecommerce": {
					"currencyCode": "RUB",
					"purchase": {
						"actionField": {
							"id" : seats?.orderId
						},
						"products": 
							seats.orderSeats.map((seat, index) => ({
								id: seat.calendarId + '_' + seat.id,
								name: seat.calendarShortName + ' ' + (seat?.calendarDay ? seat?.calendarDay : ''),
								price: seat.price,
								quantity: seat.quantity,
								position: index + 1
							}))
					}
				}});
		}
	}

	async addToBasket(seat) {
		const dataLayer = window.dataLayer || [];
		dataLayer.push({
			"ecommerce": {
				"currencyCode": "RUB",
				"add": {
					"products": [
						{
							id: seat.calendarId + '_' + seat.id,
							name: seat.calendarShortName + ' ' + (seat?.calendarDay ? seat?.calendarDay : ''),
							price: seat.price,
							quantity: seat.quantity
						}
					]
				}
		}});
	}

	async removeFromBasket(seat) {
		let calendarDay = '';
		if (seat.calendar?.day) {
			var datePart = seat.calendar?.day.match(/\d+/g),
			year = datePart[0],
			month = datePart[1],
			day = datePart[2];
			calendarDay = day + '.' + month + '.' + year;
		}
		const dataLayer = window.dataLayer || [];
		dataLayer.push({
			"ecommerce": {
				"currencyCode": "RUB",
				"remove": {
					"products": [
						{
							id: seat.calendar?.id + '_' + seat.unreserveInfo?.seatId,
							name: seat.calendar?.name + ' ' + calendarDay,
							//price: seat.price,
							quantity: seat.unreserveInfo?.quantity
						}
					]
				}
		}});
	}

	async removeFromBasketPopup(seat) {
		let calendarDay = '';
		if (seat.calendar?.calendarDate) {
			const date = new Date(seat.calendar?.calendarDate);
			const yyyy = date.getFullYear();
			let mm = date.getMonth() + 1; // Months start at 0!
			let dd = date.getDate();

			if (dd < 10) dd = '0' + dd;
			if (mm < 10) mm = '0' + mm;
			calendarDay = dd + '.' + mm + '.' + yyyy;
		}
		const dataLayer = window.dataLayer || [];
		dataLayer.push({
			"ecommerce": {
				"currencyCode": "RUB",
				"remove": {
					"products": [
						{
							id: seat.calendar?.calendarId + '_' + seat.unreserveInfo?.seatId,
							name: seat.calendar?.calendarShortName + ' ' + calendarDay,
							//price: seat.price,
							quantity: seat.unreserveInfo?.quantity
						}
					]
				}
		}});
	}
}

export const analyticModule = new Module({
	actions: AnalyticActions,
});

export const analyticMapper = createMapper(analyticModule);

export default analyticModule;