<template>
  <div class="tickets-popup-order">
    <div @click="closeAuthorizationPopup()">
      <!-- Обёртка попапа с белым фоном -->
      <div 
        class="tickets-popup-order__wrapper" 
        @click="$event.stopPropagation()" 
        :class="{'full-height': isFullHeight}"
      >
        <!-- Контнетный блок -->
        <div class="tickets-popup-order__content">
          <!-- Кнопка закрытия попапа -->
          <button class="tickets-popup-order__back" type="button" @click="closeAuthorizationPopup()">
            <svg>
              <use xlink:href="#ic_arrow_tickets_system"></use>
            </svg>
            к выбору билетов
          </button>
          <p class="authorization-popup__title">
            введите данные или 
            <a @click="authorizationHandler()" class="authorization-popup__auth-link">авторизуйтесь</a>
          </p>
          <div class="authorization-popup__input-area">
            <div class="authorization-popup__input-wrapper">
              <input 
                v-model.trim="firstName"
                :disabled="smsHashId"
                placeholder="Имя" 
                class="authorization-popup__input"
                :class="{invalid: ($v.firstName.$dirty && !$v.firstName.required)}"
                type="text"
              />
              <p 
                v-if="$v.firstName.$dirty && !$v.firstName.required"
                class="authorization-popup__danger-subtitle"
              >
                Введите имя
              </p>
            </div>

            <div class="authorization-popup__input-wrapper">
              <input 
                v-model.trim="lastName"
                :disabled="smsHashId"
                placeholder="Фамилия" 
                class="authorization-popup__input"
                :class="{invalid: ($v.lastName.$dirty && !$v.lastName.required)}"
                type="text"
              />
              <p 
                v-if="$v.lastName.$dirty && !$v.lastName.required"
                class="authorization-popup__danger-subtitle"
              >
                Введите фамилию
              </p>
            </div>

            <div class="authorization-popup__email-input">
              <input 
                v-model.trim="email"
                :disabled="smsHashId"
                placeholder="E-mail" 
                class="authorization-popup__input" 
                :class="{invalid: ($v.email.$dirty && !$v.email.required) || ($v.email.$dirty && !$v.email.email)}" 
                type="email"
              />
              <p 
                v-if="$v.email.$dirty && !$v.email.required"
                class="authorization-popup__danger-subtitle"
              >
                Введите email
              </p>
              <p 
                v-else-if="$v.email.$dirty && !$v.email.email"
                class="authorization-popup__danger-subtitle"
              >
                Неверный формат 
              </p>
              <p v-else class="authorization-popup__input-subtitle">Мы пришлём на него билеты и эл. чек.</p>
            </div>

            <div class="authorization-popup__input-wrapper">
              <input 
                v-model.trim="phone"
                :disabled="smsHashId"
                placeholder="Телефон" 
                class="authorization-popup__input" 
                :class="{invalid: ($v.phoneNumber.$dirty && !$v.phoneNumber.required)} || ($v.phoneNumber.$dirty && !$v.phoneNumber.minLength)"
                type="tel" 
                ref="phoneNumber"
              />
              <p 
                v-if="($v.phoneNumber.$dirty && !$v.phoneNumber.required) || ($v.phoneNumber.$dirty && !$v.phoneNumber.minLength)"
                class="authorization-popup__danger-subtitle"
              >
                Введите мобильный телефон
              </p>
              <p v-else class="authorization-popup__input-subtitle">
                Заказ будет привязан к этому номеру. В случае утери их будет легко восстановить.
              </p>
            </div>

            <div v-if="smsHashId" class="authorization-popup__sms-input">
              <input 
                v-model.trim="smsCode"
                placeholder="Введите код из СМС" 
                class="authorization-popup__input" 
                :class="{invalid: ($v.smsCode.$dirty && !$v.smsCode.required)}"
                type="tel"
              />
              <p 
                v-if="($v.smsCode.$dirty && !$v.smsCode.required)"
                class="authorization-popup__danger-subtitle"
              >
                Введите код из СМС
              </p>
            </div>
          </div> 
        </div>
        <div class="authorization-popup__actions">
            <button
                class="yellow-button"
                @click="submit(firstName, lastName, email, phoneNumber, smsCode, smsHashId)"
                :disabled="isLoading"
                :class="{'loading': isLoading}"
            >
              <span class="authorization-modal__come-in-title">Продолжить</span>
              <span class="loader"></span>
            </button>
          </div> 
      </div>
    </div> 
    <ErrorPopup
      v-if="isErrorPopupShown"
      @closeErrorPopup="closeErrorPopup"
      @authorizationHandler="authorizationHandler"
      :errorMessage="errorMessage"
      :isAuthorizationError="true"
    />     
  </div>
</template>

<script>
import {authorizationMapper} from '@/store/modules/authorization';

import Inputmask from 'inputmask';
import { email, required, minLength } from 'vuelidate/lib/validators';

import ErrorPopup from './ErrorPopup.vue';

export default {
  name: 'AuthorizationPopup',
  validations: {
    firstName: { required },
    lastName: { required },
    email: { email, required },
    phoneNumber: {
      required,
      minLength: minLength(10)
    },
    smsCode: {
      required,
    }
  },
  props: {
    isFullHeight: { type: Boolean },
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      smsCode: '',
      smsHashId: false,
      isLoading: false,
      errorMessage: '',
      isErrorPopupShown: false,
    }
  },
  components: {
    ErrorPopup,
  },
  mounted() {
    const inputMask = new Inputmask("+7(999)999-99-99");
    inputMask.mask(this.$refs.phoneNumber);
    const body = document.body;
    body.style.height = '100vh';
    body.style.overflowY = 'hidden';
  },
  beforeDestroy() {
    const body = document.body;
    body.style.height = '100%';
    body.style.overflowY = 'auto';
  },
  computed: {
    ...authorizationMapper.mapGetters(['simpleAuthInfo']),
    phoneNumber() {
      const pattern = /[^\d;]/g;
      return this.phone.replace(pattern, '').substring(1);
    },
  },
  methods: {
    ...authorizationMapper.mapActions(['simpleAuth', 'confirmAccount']),
    closeErrorPopup(isShowModal) {
      this.isErrorPopupShown = isShowModal;
    },
    closeAuthorizationPopup() {
      this.$emit('closeAuthorizationPopup', false);
    },
    authorizationHandler() {
      this.$emit('closeAuthorizationPopup', false, true);
    },
    async submit(firstName, lastName, email, phoneNumber, smsCode, smsHashId) {
      if (!this.smsHashId) {
        this.$v.firstName.$touch();
        this.$v.lastName.$touch();
        this.$v.email.$touch();
        this.$v.phoneNumber.$touch();

        if (this.$v.firstName.$invalid || this.$v.lastName.$invalid || this.$v.email.$invalid || this.$v.phoneNumber.$invalid) {
          return;
        }

        this.isLoading = true;

        try {
          await this.simpleAuth({firstName, lastName, email, phoneNumber});
          
          if (this.simpleAuthInfo.status === 202) {
            this.smsHashId = this.simpleAuthInfo.data.smsHashId;
          } else if (this.simpleAuthInfo.status === 200) {
            this.$emit('processSeatClick');
            this.$emit('closeAuthorizationPopup', false);
          }
        } catch (e) {
          this.errorMessage = e.data.errorMessage;
          this.isErrorPopupShown = true;
        } finally {
          this.isLoading = false;
        }
      } else {
        this.$v.smsCode.$touch();

        if (this.$v.smsCode.$invalid) {
          return;
        }

        this.isLoading = true;

        try {
          await this.confirmAccount({smsCode, smsHashId});
          this.$emit('processSeatClick');
          this.$emit('closeAuthorizationPopup', false);
        } catch (e) {
          this.errorMessage = e.errorMessage;
          this.isErrorPopupShown = true;
        } finally {
          this.isLoading = false;
        }
      }
    },
  }
}
</script>

<style lang="scss" src="@/styles/AuthorizationPopup.scss">
</style>
