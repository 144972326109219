<template>
  <div class="abonements-popup-container">
    <div class="abonements-popup">
      <button class="popup-close js-popup-close" type="button" @click="closeAbonementsCustomPopup"></button>

      <div class="abonements-popup__content">
        <ul class="abonements-popup__list">
          <li class="abonements-popup__card">
            <div class="abonements-popup__icon">
              <svg>
                <use xlink:href="#ic_abonement-weekend"></use>
              </svg>
            </div>
            <div class="abonements-popup__wrapper">
              <h3 class="abonements-popup__title">Готовый наборный абонемент выходного дня</h3>
              <p class="abonements-popup__text">Все матчи, приходящиеся на пятницу, субботу, воскресенье и праздничные дни</p>
              <button class="yellow-button abonements-popup__button abonements-popup__button--card" type="button">Выбрать</button>
            </div>
          </li>
          <li class="abonements-popup__card">
            <div class="abonements-popup__icon">
              <svg>
                <use xlink:href="#ic_abonement-top-10"></use>
              </svg>
            </div>
            <div class="abonements-popup__wrapper">
              <h3 class="abonements-popup__title">Готовый наборный абонемент «TOP-10»</h3>
              <p class="abonements-popup__text">Матчи с командами, закончившими регулярный чемпионат 2019-2020 гг. на 1-10 месте</p>
              <button class="yellow-button abonements-popup__button abonements-popup__button--card" type="button">Выбрать</button>
            </div>
          </li>
          <li class="abonements-popup__card">
            <div class="abonements-popup__icon">
              <svg>
                <use xlink:href="#ic_abonement-custom"></use>
              </svg>
            </div>
            <div class="abonements-popup__wrapper">
              <h3 class="abonements-popup__title">Наборный абонемент, собранный вами</h3>
              <p class="abonements-popup__text">Любые матчи, которые вы хотите посетить (не менее 12 матчей)</p>
              <button class="yellow-button abonements-popup__button abonements-popup__button--card" type="button">Выбрать</button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AbonementsCustomPopup',
  methods: {
    closeAbonementsCustomPopup() {
      this.$emit('closeAbonementsCustomPopup', false);
    },
  }
}
</script>
