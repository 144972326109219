<template>
  <div class="content">
    <div class="abonement-matches2">
      <div v-if="!calendarListById" class="abonements__spinner">
      <half-circle-spinner
        v-if="isContentLoading"
        :animation-duration="2000"
        :size="60"
        :color="'#0a275f'"
        class="abonements-loader"
      />
    </div>
      <div v-else class="abonement-matches2__container">
        <div class="abonement-matches2__header">
          <div class="abonement-matches2__header-top">
            <h1>Календарный план матчей</h1>
            <!-- <div class="abonement-matches2__season">
              <div class="season-filter">
                <a href="#" class="season-filter__show-seasons js-filter-season opened" data-dropdown-id="seasons">
                  2020-2021. Регулярный чемпионат
                  <svg>
                    <use xlink:href="#ic_chevron"></use>
                  </svg>
                </a>
                <div class="dropdown dropdown-list js-season-dropdown" style="" data-dropdown-id="seasons">
                  <div class="dropdown-in ps ps--active-y">
                    <ul>
                      <li data-season-id="2901">
                        <span id="s_2901">2020-2021. Регулярный чемпионат</span>
                        <a id="a_2901" href="#" style="display: none">2020-2021. Регулярный чемпионат</a>
                      </li>
                      <li data-season-id="2900">
                        <span id="s_2900" style="display: none">2020. Межсезонье</span>
                        <a id="a_2900" href="#">2020. Межсезонье</a>
                      </li>
                        <li data-season-id="2881">
                          <span id="s_2881" style="display: none">2019-2020. Плей-офф</span>
                          <a id="a_2881" href="#">2019-2020. Плей-офф</a>
                        </li>
                        <li data-season-id="2662">
                          <span id="s_2662" style="display: none">2019-2020. Регулярный чемпионат</span>
                          <a id="a_2662" href="#">2019-2020. Регулярный чемпионат</a>
                        </li>
                        <li data-season-id="2663">
                          <span id="s_2663" style="display: none">2019. Межсезонье</span>
                          <a id="a_2663" href="#">2019. Межсезонье</a>
                        </li>
                        <li data-season-id="2632">
                          <span id="s_2632" style="display: none">2018-2019. Плей-офф </span>
                          <a id="a_2632" href="#">2018-2019. Плей-офф </a>
                        </li>
                        <li data-season-id="2402">
                          <span id="s_2402" style="display: none">2018-2019. Регулярный чемпионат</span>
                          <a id="a_2402" href="#">2018-2019. Регулярный чемпионат</a>
                        </li>
                        <li data-season-id="2361">
                          <span id="s_2361" style="display: none">2018. Межсезонье</span>
                          <a id="a_2361" href="#">2018. Межсезонье</a>
                        </li>
                        <li data-season-id="2259">
                          <span id="s_2259" style="display: none">2017-2018. Плей-офф</span>
                          <a id="a_2259" href="#">2017-2018. Плей-офф</a>
                        </li>
                        <li data-season-id="1497">
                          <span id="s_1497" style="display: none">2017-2018. Регулярный чемпионат</span>
                           <a id="a_1497" href="#">2017-2018. Регулярный чемпионат</a>
                        </li>
                        <li data-season-id="1478">
                          <span id="s_1478" style="display: none">2017. Межсезонье</span>
                          <a id="a_1478" href="#">2017. Межсезонье</a>
                        </li>
                        <li data-season-id="1416">
                          <span id="s_1416" style="display: none">2016-2017. Плей-офф</span>
                           <a id="a_1416" href="#">2016-2017. Плей-офф</a>
                        </li>
                        <li data-season-id="1226">
                          <span id="s_1226" style="display: none">2016-2017. Регулярный чемпионат</span>
                          <a id="a_1226" href="#">2016-2017. Регулярный чемпионат</a>
                        </li>
                        <li data-season-id="1205">
                          <span id="s_1205" style="display: none">2016. Межсезонье</span>
                          <a id="a_1205" href="#">2016. Межсезонье</a>
                        </li>
                        <li data-season-id="1120">
                          <span id="s_1120" style="display: none">2015-2016. Плей-офф</span>
                          <a id="a_1120" href="#">2015-2016. Плей-офф</a>
                        </li>
                        <li data-season-id="898">
                          <span id="s_898" style="display: none"> 2015-2016. Регулярный чемпионат</span>
                          <a id="a_898" href="#"> 2015-2016. Регулярный чемпионат</a>
                        </li>
                          <li data-season-id="894">
                            <span id="s_894" style="display: none">2015. Межсезонье</span>
                            <a id="a_894" href="#">2015. Межсезонье</a>
                        </li>
                        <li data-season-id="844">
                          <span id="s_844" style="display: none">2014-2015. Плей-офф</span>
                          <a id="a_844" href="#">2014-2015. Плей-офф</a>
                        </li>
                        <li data-season-id="611">
                          <span id="s_611" style="display: none">2014-2015. Регулярный чемпионат</span>
                          <a id="a_611" href="#">2014-2015. Регулярный чемпионат</a>
                        </li>
                        <li data-season-id="587">
                          <span id="s_587" style="display: none">2014. Межсезонье</span>
                          <a id="a_587" href="#">2014. Межсезонье</a>
                        </li>
                        <li data-season-id="468">
                          <span id="s_468" style="display: none">2013-2014. Плей-офф</span>
                          <a id="a_468" href="#">2013-2014. Плей-офф</a>
                        </li>
                        <li data-season-id="183">
                          <span id="s_183" style="display: none">2013-2014. Регулярный чемпионат</span>
                          <a id="a_183" href="#">2013-2014. Регулярный чемпионат</a>
                        </li>
                        <li data-season-id="190">
                          <span id="s_190" style="display: none">2013. Межсезонье</span>
                          <a id="a_190" href="#">2013. Межсезонье</a>
                        </li>
                        <li data-season-id="100">
                          <span id="s_100" style="display: none">2012-2013. Плей-офф</span>
                          <a id="a_100" href="#">2012-2013. Плей-офф</a>
                        </li>
                        <li data-season-id="2">
                          <span id="s_2" style="display: none">2012-2013. Регулярный чемпионат</span>
                          <a id="a_2" href="#">2012-2013. Регулярный чемпионат</a>
                        </li>
                        <li data-season-id="99">
                          <span id="s_99" style="display: none">2011-2012. Плей-офф</span>
                          <a id="a_99" href="#">2011-2012. Плей-офф</a>
                        </li>
                        <li data-season-id="98">
                          <span id="s_98" style="display: none">2011-2012. Регулярный чемпионат</span>
                          <a id="a_98" href="#">2011-2012. Регулярный чемпионат</a>
                        </li>
                        <li data-season-id="97">
                          <span id="s_97" style="display: none">2010-2011. Плей-офф</span>
                          <a id="a_97" href="#">2010-2011. Плей-офф</a>
                        </li>
                        <li data-season-id="5">
                          <span id="s_5" style="display: none">2010-2011. Регулярный чемпионат</span>
                          <a id="a_5" href="#">2010-2011. Регулярный чемпионат</a>
                        </li>
                        <li data-season-id="96">
                          <span id="s_96" style="display: none">2009-2010. Плей-офф</span>
                          <a id="a_96" href="#">2009-2010. Плей-офф</a>
                        </li>
                        <li data-season-id="4">
                          <span id="s_4" style="display: none">2009-2010. Регулярный чемпионат</span>
                          <a id="a_4" href="#">2009-2010. Регулярный чемпионат</a>
                        </li>
                        <li data-season-id="95">
                          <span id="s_95" style="display: none">2008-2009. Плей-офф</span>
                          <a id="a_95" href="#">2008-2009. Плей-офф</a>
                        </li>
                        <li data-season-id="3">
                          <span id="s_3" style="display: none">2008-2009. Регулярный чемпионат</span>
                          <a id="a_3" href="#">2008-2009. Регулярный чемпионат</a>
                        </li>
                    </ul>
                  <div class="ps__rail-x" style="left: 0px; bottom: -159px;"><div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div></div><div class="ps__rail-y" style="top: 159px; height: 288px; right: 0px;"><div class="ps__thumb-y" tabindex="0" style="top: 32px; height: 58px;"></div></div></div>
                </div>
              </div>
            </div> -->
          </div>

          <p class="abonement-matches2__description">Для болельщиков, <span>не являющихся</span> владельцами абонемента сезона 2021/22</p>
          <p class="abonement-matches2__selected">Выбрано <span>{{selectedFileIds.length}}</span> матчей</p>
          <p v-if="selectedFileIds.length < minQuantity" class="abonement-matches2__selected abonement-matches2__selected--min">Минимум:
              <span>{{minQuantity}}</span>
          </p>
          <p v-else class="abonement-matches2__selected abonement-matches2__selected--min">Максимум:
              <span>{{maxQuantity}}</span>
          </p>
        </div>

        <form class="abonement-matches2__schedule">
          <section v-for="eventByMonth in eventsByMonth" :key="eventByMonth.id" class="abonement-matches2__month matches-month">
            <h3>{{moment(eventByMonth[0].day).locale('ru').format('MMMM')}}</h3>
            <ul class="matches-month__games">
              <li v-for="event in eventByMonth" :key="event.id" class="matches-month__game">
                <input 
                  :id="event.id"
                  :value="event.id"
                  v-model="selectedFileIds"
                  @click="updateURL(event.id)"
                  type="checkbox"
                  class="visually-hidden"
                  name="games[]"
                >

                <div class="matches-month__game-wrapper">
                  <label :for="event.id" class="matches-month__checkbox"></label>
                  <div class="matches-month__date">
                    <time datetime="2021-09-01 19:30">{{moment(event.day).locale('ru').format('DD MMMM')}} <span>{{event.time.substr(0, 5)}}</span></time>
                    <p class="matches-month__weekday">{{moment(event.day).locale('ru').format('dddd')}}</p>
                  </div>
                  <div class="matches-month__opposition">
                    <div class="matches-month__flag">
                      <img :src="event.guestTeamLogoLink" :alt="event.guestTeamName">
                    </div>
                    <h4>{{event.guestTeamName}}</h4>
                  </div>
                </div>
              </li>
            </ul>  
          </section>
        </form>

        <footer class="abonement-matches2__footer">
          <!-- <p class="abonement-matches2__sum">
            Cтоимость абонемента от: <br>
            <span>1 600 ₽</span>
          </p> -->
          <button 
            class="yellow-button" 
            type="button" 
            :disabled="selectedFileIds.length < minQuantity || selectedFileIds.length > maxQuantity"
            @click="choosePlace"
          >
            Выбрать место
          </button>
        </footer>
      </div>
    </div>
    <ErrorPopup
      v-if="isErrorPopupShown"
      @closeErrorPopup="closeErrorPopup"
      :errorMessage="errorMessage"
    />
  </div>
</template>

<script>
import router from '@/router';
import { calendarMapper } from '@/store/modules/calendar';

import { HalfCircleSpinner } from 'epic-spinners';

import ErrorPopup from '../ErrorPopup.vue';

export default {
  name: 'AbonementsCalendarMatches',
  components: {
    HalfCircleSpinner,
    ErrorPopup,
  },
  data() {
    return {
      isContentLoading: false,
      errorMessage: '',
      isErrorPopupShown: false,
      eventsCounter: 0,
      selectedFileIds: [],
      minQuantity: this.$route.params.minQuantity,
      maxQuantity: this.$route.params.maxQuantity,
      queryParams: {},
    }
  },
  async mounted() {
    try {
      this.isContentLoading = true,
      await this.getCalendarListById(this.$route.params.calendarId);
    } catch(error) {
      if (error.status === 502) {
        this.errorMessage = "Ошибка связи с сервером.";
        this.isErrorPopupShown = true;
      }
    } finally {
      this.isContentLoading = false; 
    }
  },
  methods: {
    ...calendarMapper.mapActions(['getCalendarListById', 'getAvailableSectorsList',]),
    choosePlace() {
      router.push({ 
        name: 'games', 
        params: { calendarId: this.$route.params.calendarId, selectedFileIds: this.selectedFileIds },
        query: { seasonIds: this.queryParams }
      });
    },
    updateURL(eventId) {
      let index = this.selectedFileIds.findIndex(a => a === eventId)
      if (index >= 0) {
        this.selectedFileIds.splice(index, 1)
      }
      else {
        this.selectedFileIds.push(eventId);
      }

      this.queryParams = Object.keys(this.selectedFileIds).map(key => this.selectedFileIds[key]);

      history.pushState(
        {},
        null,
        this.$route.path +
          '?' +
          Object.keys(this.selectedFileIds)
            .map(key => {
              return (
                encodeURIComponent('seasonIds') + '=' + encodeURIComponent(this.selectedFileIds[key])
              )
            })
            .join('&')
      );
		},
    closeErrorPopup(isShowModal) {
      this.isErrorPopupShown = isShowModal;
    },
  },
  computed: {
    ...calendarMapper.mapGetters(['calendarListById']),
    eventsByMonth() {
      return this.calendarListById.filter(event => event.algorithmSaleId !== 540).reduce((acc, event) => {
        let idx = acc.findIndex(elem => this.moment(elem[0].day).locale('ru').format('MMMM') == this.moment(event.day).locale('ru').format('MMMM'));
        if (idx !== -1) acc[idx].push(event);
        else acc.push([event]);
        return acc;
      }, []);
    },
  },
}
</script>

<style scoped>
  .abonements-loader {
    position: absolute;
    left: 50%;
    z-index: 10;
  }

  .matches-month__weekday::first-letter {
    text-transform: uppercase;
}
</style>
