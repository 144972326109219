<template>
  <div class="content">
    <div class="sponsors">
      <h1>Заказы на матчи</h1>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Sponsors',
  data() {
    return {
    }
  },
  components: {
  },
  async mounted() {
  },
  computed: {

  },  
  methods: {

  },
}
</script>

<style lang="scss" src="@/styles/Sponsors.scss">
</style>
