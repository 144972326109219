<template>
  <div class="overlay2">
    <div class="ticket-view-modal">
      <div class="ticket-view-modal__wrapper">
        <!--<div style="display: flex; justify-content: space-between;">
           <button @click="closeModal" style="height: 24px; border: none; background: none;">
            <svg style="width: 16px; height: 16px;"><use xlink:href="#ic_close"></use></svg>
          </button> 
        </div>-->
        <div class="list">
          <qr-code :text="ticket.barcode" style="width: 120px;margin-left: auto;margin-right: auto;"></qr-code>
          <p></p>
          <p></p>
          <p></p>
          <p v-if="ticket.algorithmSaleId === 510" class="ticket-view-modal__subtitle">{{ ticket.actionFullName }} {{ ticket.actionDay }}  {{ ticket.actionTime }} </p>
          <p v-if="ticket.algorithmSaleId != 510" class="ticket-view-modal__subtitle">{{ ticket.actionFullName }} </p>
          <p class="ticket-view-modal__subtitle">{{ ticket.ticketSeatFullName }}</p> 
        </div>
        <div class="ticket-view-modal__actions">
          <button class="yellow-button" @click="closeModal">Закрыть</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// import QrcodeVue from 'qrcode.vue'
import Vue from 'vue'
import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code', VueQRCodeComponent);
export default {
  name: 'TicketViewModal',
  
  components: {
    // QrcodeVue,
  //  qr-code,
  //  VueQRCodeComponent,
    
  },
  data() {
    return {
    }
  },
  computed: {
    
  },
  methods: {
    closeModal() {
      this.$emit('closeTicketModal', false);
    },

    

  },
  props: { 
   ticket: { type: Object },
  }
}
</script>

<style lang="scss" src="@/styles/TicketViewModal.scss">
</style>
