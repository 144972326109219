<template>
    <div class="payorderemail-modal__wrapper">
      <p class="payorderemail-modal__title">Оплата Заказа</p>

      <div class="apayorderemail-modal__input-area" :class="{error: error}">
        <input placeholder="Номер брони" class="payorderemail-modal__input" v-model="orderId" type="text"/>
        <br/>
        <input placeholder="E-mail" class="payorderemail-modal__input" v-model="email" type="text"/>
        <span class="payorderemail-modal__error"><span v-if="error">{{error}}</span></span>
      </div>
      <div class="payorderemail-modal__actions">
        <button
            class="yellow-button"
            @click="orderpay({orderId, email})"
            :disabled="isLoading"
            :class="{'loading': isLoading}"
        >
          <span class="payorderemail-modal__come-in-title">Оплатить</span>
          <span class="loader"></span>
        </button>
      </div>
    </div>
</template>

<script>

import {orderpayemailMapper} from '@/store/modules/orderpayemail';

//export default {
//  name: 'PayOrderByEmailIdPage',
//}

export default {
  //name: 'orderpayemailModal',
  data() {
    return {
      orderId: '',
      email: '',
      isLoading: false,
      error: null
    }
  },

  methods: {
    ...orderpayemailMapper.mapActions(['orderpaybyemail']),

    async orderpay({orderId, email}) {
      this.isLoading = true;
      this.error = null;
      try {
        //await this.orderpaybyemail({orderId, email});
        const response = await this.orderpaybyemail({orderId, email});
        const container = document.createElement('div');
        container.innerHTML = response;

        document.body.appendChild(container);
        container.querySelector('form').submit();

      } catch (e) {
        this.error = e.errorMessage;
      } finally {
        this.isLoading = false;
      }

    },
  },
}

</script>



<style lang="scss" src="@/styles/Payorderemail.scss">
</style>
