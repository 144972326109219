<template>
  <div class="game-main-info">
    <div class="game-main-info__game-title" v-if="gameInfo.ownerTeamName && gameInfo.guestTeamName">
      <div class="game-main-info__team-name">
        <img class="game-main-info__team-img" v-if="gameInfo.ownerTeamLogoLink" :src="gameInfo.ownerTeamLogoLink" />
        <span class="game-main-info__team-title">{{ `${gameInfo.ownerTeamName}` }}</span>
      </div>
      <div class="game-main-info__separator1">—</div> 
      <div class="game-main-info__separator2">VS</div> 
      <div class="game-main-info__team-name">
        <img class="game-main-info__team-img" v-if="gameInfo.guestTeamLogoLink" :src="gameInfo.guestTeamLogoLink" />
        <span class="game-main-info__team-title">{{ `${gameInfo.guestTeamName}` }}</span>
      </div>
    </div>
    <div class="game-main-info__game-title" v-if="!gameInfo.ownerTeamName && gameInfo.guestTeamName">
      <div class="game-main-info__team-name">
        <img class="game-main-info__team-img" v-if="gameInfo.guestTeamLogoLink" :src="gameInfo.guestTeamLogoLink" />
        <span class="game-main-info__team-title">{{ `${gameInfo.guestTeamName}` }}</span>
      </div>
    </div>
    <div class="game-main-info__game-title" v-if="!gameInfo.ownerTeamName && !gameInfo.guestTeamName">
      <div class="game-main-info__team-name">
        <span class="game-main-info__team-title">{{ `${gameInfo.name}` }}</span>
      </div>
    </div>
    <div class="tickets-system-local__desc">
        <h3 v-if="gameInfo.algorithmSaleId == 510">{{ moment(gameInfo.day).locale('ru').format('DD MMMM (dddd)') }} в {{ `${gameInfo.time.substr(0, 5)}` }}</h3>
        <p class="text" v-if="gameInfo.algorithmSaleId == 510">
          {{ `${gameInfo.actionTypeWeb[0].name ? gameInfo.actionTypeWeb[0].name : ''} ${gameInfo.seasonName ? gameInfo.seasonName : ''}` }}. Начало матча по местному времени — {{ `${gameInfo.time.substr(0, 5)}` }}.
        </p>
        <p class="text" v-if="gameInfo.algorithmSaleId == 520">
          {{ `${gameInfo.actionTypeWeb[0].name ? gameInfo.actionTypeWeb[0].name : ''} ${gameInfo.seasonName ? gameInfo.seasonName : ''}` }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GameMainInfo',
  props: { 
    gameInfo : { type: Object}
  }
}
</script>

<style lang="scss" src="@/styles/GameMainInfo.scss">
</style>
