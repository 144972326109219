import axios from 'axios';

// const api = {
//   url: `https://test-tickets.hcsochi.ru`,
// };

export default {
	async login(userInfo) {
		try {
			const url = `/webapi/auth/login`;
			const response = await axios.post(url, {
				"password": userInfo.password,
				"login": userInfo.username
			});

			return response.data;
		} catch (e) {
			throw e.response.data;
		}
	},

  async logout() {
    try {
      const url = `/webapi/auth/logout`;
      await axios.post(url);

    } catch {
      throw new Error('Failed to logout. Please try again later.');
    }
  },

  async getUserInfo() {
    try {
      const url = `/webapi/auth/me`;
      const response = await axios.get(url);

      return response.data;
    } catch {
      throw new Error('Failed to get user info. Please try again later.');
    }
  },

  async getPrincipalInfo() {
    try {
      //const url = `/webapi/auth/principal-info`;
      const url = `/webapi/auth/me`;
      const response = await axios.get(url);

      return response.data;
    } catch {
      throw new Error('Failed to get principal info. Please try again later.');
    }
  },

  async simpleAuth(simpleAuthInfo) {
    const formData = new FormData();
    formData.append('firstName', simpleAuthInfo.firstName);
    formData.append('lastName', simpleAuthInfo.lastName);
    formData.append('email', simpleAuthInfo.email);
    formData.append('phone', simpleAuthInfo.phoneNumber);

    try {
			const url = `/webapi/simple-auth/attempt`;
			const response = await axios.post(url, formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

			return response;
		} catch (e) {
			throw e.response;
		}
  },

  async confirmAccount(smsInfo) {
    const formData = new FormData();
    formData.append('smsCode', smsInfo.smsCode);
    formData.append('smsHashId', smsInfo.smsHashId);

    try {
			const url = `/webapi/simple-auth/confirm-account`;
			const response = await axios.post(url, formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

			return response.data;
		} catch (e) {
			throw e.response.data;
		}
  },

  async simpleAuthLogout() {
    try {
      const url = `/webapi/simple-auth/logout`;
      const response = await axios.post(url);

      return response.data;
    } catch {
      throw new Error('Failed to logout. Please try again later.');
    }
  }
};
