<template>
  <div class="choice-ticket__bottom">
    <!-- <h3 class="choice-ticket__bottom-title">Популярные вопросы</h3> -->
    <div class="choice-ticket__bottom-inner que-struck">

      <div>
        <div v-if="calendarQuant > 1" class="que-tittle">Популярные вопросы</div>
        <div v-if="calendarQuant < 2" class="que-tittle-white">Популярные вопросы</div>
        <ul v-if="calendarQuant > 1" class="que">
          <li class="button-que"  :class="{active: button.id === activeButton}"
                                  v-for="button in buttonsQuestions" :key="button.id">
            <button @click="getActiveButton(button.id)" type="button" class="button-que-text">{{button.name}}</button>
          </li> 
        </ul>
        <ul v-if="calendarQuant < 2" class="que">
          <li class="button-que"  :class="{activewhite: button.id === activeButton}"
                                  v-for="button in buttonsQuestions" :key="button.id">
            <button @click="getActiveButton(button.id)" type="button" class="button-que-text-white">{{button.name}}</button>
          </li> 
        </ul>
        <div class="choice-ticket__qustions hide" style="flex-shrink: 1;" :class="{display: activeButton === 1}">
          <!-- <div class="questions__title-group">Вопросы по билетам</div> -->
          <div class="choice-ticket__qustions-wrapper">
            <ul class="choice-ticket__qustions-list">
              <li v-for="question in ticketQuestions" :key="question.question" class="choice-ticket__qustions-item"
                  :class="{opened: question.opened}" @click="question.opened = !question.opened">
                <div class="qustion-header">
                  <h4 class="qustion-title">
                    {{ question.question }}
                  </h4>
                  <svg class="qustion-icon-arrow">
                    <use xlink:href="#ic_arrow-next"></use>
                  </svg>
                </div>
                <p class="qustion-content" v-html="question.answer" style="text-align: justify;color:#0A275F;">
                </p>
              </li>
            </ul>
          </div>
        </div>
        
        <div class="choice-ticket__qustions hide" style="flex-shrink: 1;" :class="{display: activeButton === 2}">
          <!-- <div class="questions__title-group">Общие вопросы</div> -->
          <div class="choice-ticket__qustions-wrapper">
            <ul class="choice-ticket__qustions-list">
              <li v-for="question in popularQuestions" :key="question.question" class="choice-ticket__qustions-item"
                  :class="{opened: question.opened}" @click="question.opened = !question.opened">
                <div class="qustion-header">
                  <h4 class="qustion-title">
                    {{ question.question }}
                  </h4>
                  <svg class="qustion-icon-arrow">
                    <use xlink:href="#ic_arrow-next"></use>
                  </svg>
                </div>
                <p class="qustion-content" v-html="question.answer" style="text-align: justify;color:#0A275F;">
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="ticket-office">
        <h3 class="ticket-office__tittle">Гдe купить билеты оффлайн</h3>
        <ol class="ticket-office__point-list">
          <li v-for="info in offlineSection" :key="info.address" class="ticket-office__point-item">
            <div class="ticket-office__point-header">{{ info.address1 }}</div>
            <div class="ticket-office__point-header">{{ info.address2 }}</div>
            <div class="ticket-office__point-info">
              <span class="ticket-office__point-desc">{{ info.location }}</span>
              <span class="ticket-office__point-time">{{ info.time }}</span>
            </div>
          </li>
        </ol>
        <div class="ticket-office__point-link">
          <a class="ticket-office__point-link" href="https://yandex.ru/maps/-/CDQzRZlg" target="_blank">
            показать на карте
            <!-- <svg class="icon">
              <use xlink:href="#ic_arrow-right"></use>
            </svg> -->
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import popularQuestions from '@/mocks/popularQuestions.json';
import ticketQuestions from '@/mocks/ticketQuestions.json';
import offlineSection from '@/mocks/offlineSection.json';
import buttonsQuestions from '@/mocks/buttonsQuestions.json';

export default {
  name: 'Questions',
  props: {
    calendarQuant: { type: Number },
  },
  data() {
    return {
      activeButton: 1,
      popularQuestions,
      ticketQuestions,
      offlineSection,
      buttonsQuestions
    }
  },
  methods: {
    getActiveButton(id) {
      this.activeButton = id;
    },
  }
}
</script>

<style lang="scss" src="@/styles/Questions.scss">
</style>
