<template>
  <section class="abonements-main__kinds">
    <div class="abonements-main__container">
      <h2 class="abonements-main__title abonements-main__title--gap">Виды абонементов</h2>
      <ul class="abonements-main__kind-list">
        <li v-for="abon in abonementsFiltered" :key="abon.id"  class="abonements-main__kind abonements-main__abonement-card  abonement-kinds">
          <div>
            <h3 class="abonement-kinds__title">{{abon.name}}</h3>
            <ul class="abonement-kinds__features">
              <li class="abonement-kinds__feature">
                {{abon.arenaName}}
              </li>
              <!-- <li class="abonement-kinds__feature">
                Для работников любой  компании, при условии наличия коллективной заявки от 50 человек.  
              </li> -->
            </ul>
          </div>
          <div class="abonement-kinds__footer">
              <p class="abonement-kinds__card-price">от {{abon.minPrice}}  ₽</p>
              <button 
                type="button" 
                class="abonement-kinds__button yellow-button"
                :disabled="!abon"
                @click="openAbonement(abon.id)"
              >
                Выбрать место
              </button>
          </div>
        </li>

        <!-- <li class="abonements-main__kind abonements-main__abonement-card abonement-kinds abonement-kinds--with-image">
          <div>
            <h3 class="abonement-kinds__title">Мини</h3>
            <ul class="abonement-kinds__features">
              <li class="abonement-kinds__feature">
                Готовые комплекты билетов со скидкой на ближайшие игры 
              </li>
            </ul>
          </div>
          <div class="abonement-kinds__footer">
              <p class="abonement-kinds__card-price">от 1 500  ₽</p>
              <button
                type="button"
                class="abonement-kinds__button yellow-button"
                @click="showAbonementsMiniPopup"
              >
                Выбрать место
              </button>
          </div>
          
          <img src="https://hcsochi.ru/_assets/redesign2020/img/test/type-abonement-icon.png" alt="">
         
        </li> -->

        <!-- <li class="abonements-main__kind abonements-main__abonement-card abonement-kinds abonement-kinds--with-image">
          <div>
            <h3 class="abonement-kinds__title">Наборный</h3>
            <ul class="abonement-kinds__features">
              <li class="abonement-kinds__feature">
                При покупке этого абонемента вы выбираете 12 любых игр среди домашних матчей  
              </li>
            </ul>
          </div>
          <div class="abonement-kinds__footer">
              <p class="abonement-kinds__card-price">от 1 500  ₽</p>
              <button
                type="button"
                class="abonement-kinds__button yellow-button" 
                @click="openAbonementsChoice"
              >
                Выбрать матчи
              </button>
          </div>
        </li> -->


        <!-- <li class="abonements-main__kind abonement-kinds">
          <h3 class="abonement-kinds__title">Семейный абонемент</h3>
          <ul class="abonement-kinds__features">
            <li class="abonement-kinds__feature">
              Сектор С
            </li>
            <li class="abonement-kinds__feature">
              С&nbsp;одним взрослым билетом можно купить 1&nbsp;или 2&nbsp;детских /
              с&nbsp;двумя взрослыми билетами можно купить от&nbsp;1&nbsp;до&nbsp;3&nbsp;детских
            </li>
            <li class="abonement-kinds__feature">
              50% скидка от стоимости полного абонемента в аналогичный сектор
            </li>
            
          </ul> -->
         <!-- <p class="abonement-kinds__price">В ПРОДАЖЕ с 19 июля</p>-->
          <!-- <div class="abonement-kinds__footer">
            <button type="button" class="abonement-kinds__button yellow-button" 
              :disabled="!abonementsFamily[0]"
              @click="openFamilyAbonement">
              Выбрать место
            </button>
          </div>
        </li> -->

        <!-- <li class="abonements-main__kind abonement-kinds abonement-kinds--social">
          <h3 class="abonement-kinds__title">Социальный</h3>
          <ul class="abonement-kinds__features">
            <li class="abonement-kinds__feature">
              для инвалидов 1, 2, 3 групп, пенсионеров, ветеранов труда и ВОВ, школьников
              и&nbsp;студентов очной формы обучения;
            </li>
          </ul>

          <div class="abonement-kinds__footer">
            <p class="abonement-kinds__description">
              Вход в Арену "ХК Сочи" по данному абонементу разрешается только при наличии документа, который подтверждает льготу.
            </p>
            <p class="abonement-kinds__price">от 7 000 ₽ <span class="abonement-kinds__note-star">*</span></p>
            <p class="abonement-kinds__note abonement-kinds__note--show">
              <span class="abonement-kinds__note-star">*</span>
              Инвалиды 1-й группы посещают матчи бесплатно. Но оформить абонемент все равно надо —
              его нужно предъявлять на входе.
            </p>
          </div>
        </li> -->
      </ul>
      
      <button type="button" class="abonements-main__shedule">
        <svg>
          <use xlink:href="#ic_download"></use>
        </svg>
        <a style="color:#0a275f;" href="/resources/files/season_tickets_24-25.jpg" target="_blank">Ценовые категории абонементов</a>
      </button>
     
    </div>
    <AbonementsCustomPopup 
      v-if="isAbonementsCustomPopupShown" 
      @closeAbonementsCustomPopup="closeAbonementsCustomPopup"
    />
    <AbonementsMiniPopup 
      v-if="isAbonementsMiniPopupShown" 
      :miniCalendarList="calendarListById"
      :abonementsMiniId="this.abonementsMini[0].id"
      @closeAbonementsMiniPopup="closeAbonementsMiniPopup"
    />
  </section>
</template>

<script>
import router from '@/router';

import { calendarMapper } from '@/store/modules/calendar';

import AbonementsCustomPopup from './AbonementsCustomPopup.vue';
import AbonementsMiniPopup from './AbonementsMiniPopup.vue';

export default {
  name: 'AbonementsKinds',
  data() {
    return {
      isAbonementsCustomPopupShown: false,
      isAbonementsMiniPopupShown: false,
    }
  },
  components: {
    AbonementsCustomPopup,
    AbonementsMiniPopup,
  },
  computed: {
    ...calendarMapper.mapGetters(['availCalendarList', 'calendarListById']),
    abonementsFiltered() {
      return this.availCalendarList.filter(s => s.id > 0).sort((a, b) => a.id - b.id);
    },
    abonementsAll() {
      return this.availCalendarList.filter(event => 
        event.seasonLocationName === "ALL"
      );
    },
    abonementsMini() {
      return this.availCalendarList.filter(event => 
        event.seasonLocationName === "MINI"
      );
    },
    abonementsFamily() {
      return this.availCalendarList.filter(event => 
        event.seasonLocationName === "FAMILY"
      );
    },
    abonementsChoice() {
      return this.availCalendarList.filter(event => 
        event.seasonLocationName === "CHOICE"
      );
    }
  },
  methods: {
    ...calendarMapper.mapActions(['getCalendarListById']),
    openAbonement(id) {
      router.push({ name: 'games', params: { calendarId: id } });
    },
    openALLAbonement() {
      router.push({ name: 'games', params: { calendarId: this.abonementsAll[0].id } });
    },
    openFamilyAbonement() {
      router.push({ name: 'games', params: { calendarId: this.abonementsFamily[0].id } });
    },
    showAbonementsCustomPopup(isShowModal) {
      this.isAbonementsCustomPopupShown = isShowModal;
    },
    closeAbonementsCustomPopup(isShowModal) {
      this.isAbonementsCustomPopupShown = isShowModal;
    },
    async showAbonementsMiniPopup(isShowModal) {
      await this.getCalendarListById(this.abonementsMini[0].id);
      this.isAbonementsMiniPopupShown = isShowModal;
    },
    async openAbonementsChoice() {
      router.push({ name: 'multiAbonement', params: { 
        calendarId: this.abonementsChoice[0].id,
        minQuantity: this.abonementsChoice[0].minQuantity,
        maxQuantity: this.abonementsChoice[0].maxQuantity,
      } });
    },
    closeAbonementsMiniPopup(isShowModal) {
      this.isAbonementsMiniPopupShown = isShowModal;
    },
  },
}
</script>

<style lang="scss" src="@/styles/Abonements.scss">
</style>
