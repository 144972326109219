import {Module, Actions, createMapper} from 'vuex-smart-module';

import orderpayemailApi from '@/services/orderpayemail.api';


export class OrderpayemailActions extends Actions {
	async orderpaybyemail({orderId,email}) {
		return await orderpayemailApi.orderpaybyemail({orderId,email});
	}
}


export const orderpayemailModule = new Module({
	actions: OrderpayemailActions,
});

export const orderpayemailMapper = createMapper(orderpayemailModule);

export default orderpayemailModule;
