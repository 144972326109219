<template>
  <div class="abonements-popup-container">
    <div class="abonements-popup">
      <button class="popup-close js-popup-close" type="button" @click="closeAbonementsMiniPopup"></button>

      <div class="abonements-popup__content">
        <h3 class="abonements-popup__title">Купить мини-абонемент</h3>
        <p>Готовый комплект билетов на ближайшие игры со скидкой:</p>
        <ol class="abonements-popup__games">
          <li 
            v-for="calendar in miniCalendarList" 
            :key="calendar.id"
            class="abonements-popup__game">
            <div class="abonements-popup__schedule">
              <time class="abonements-popup__date">{{moment(calendar.day).locale('ru').format('DD MMMM')}}<span>{{calendar.time.substr(0, 5)}}</span></time>
              <p class="abonements-popup__day">{{moment(calendar.day).locale('ru').format('dddd')}}</p>
            </div>
            <div class="abonements-popup__opposition">
              <div class="abonements-popup__flag">
                <img :src="calendar.guestTeamLogoLink" :alt="calendar.guestTeamName">
              </div>
              <h4>{{calendar.guestTeamName}}</h4>
            </div>
          </li>
        </ol>
        <div class="abonements-popup__footer">
            <button class="yellow-button abonements-popup__button" @click="choosePlaceHandler">Выбрать место</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router';

export default {
  name: 'AbonementsMiniPopup',
  props: {
    miniCalendarList: { type: Array },
    abonementsMiniId: { type: Number }
  },
  methods: {
    choosePlaceHandler() {
      router.push({ name: 'games', params: { calendarId: this.abonementsMiniId } });
    },
    closeAbonementsMiniPopup() {
      this.$emit('closeAbonementsMiniPopup', false);
    },
  }
}
</script>
