import Vue from 'vue'
import App from './App.vue'
import store from '@/store';
import VueRouter from 'vue-router';
import router from '@/router';
import Vuelidate from 'vuelidate';
import VueCarousel from 'vue-carousel';

import moment from 'moment'

import '@/styles/index.scss';

Vue.config.productionTip = false

Vue.prototype.moment = moment;

Vue.use(VueRouter);
Vue.use(Vuelidate);
Vue.use(VueCarousel);

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
