<template>
  <div>
    <div ref="sliderWrapper" class="choice-ticket__slider-wrapper">
      <div 
        :class="{ 'choice-ticket__slider-item choice-ticket__slider-item--event' : calendar.siteBigView == 1 }"
        class="choice-ticket__slider-item"
        v-for="calendar in getCurrentCalendarByMonth"
        :key="calendar.id"
      >
      <div>
        <!-- <router-link :to="{ name: 'games', params: { calendarId: calendar.id } } "> -->
          <div class="game-card">
            <div class="game-card__main-info">
              <div v-if="calendar.algorithmSaleId === 510 || calendar.algorithmSaleId === 516" class="game-day">{{ moment(calendar.day).locale('ru').format('dddd') }}</div>
              <div v-if="calendar.algorithmSaleId === 510 || calendar.algorithmSaleId === 516" class="game-date">{{ moment(calendar.day).locale('ru').format('DD MMMM') }}, {{ calendar.time.substr(0, 5) }}</div>
              <div class="game-arena">{{ calendar.arenaName }}</div>
            </div>
            <div v-if="calendar.guestTeamLogoLink && calendar.ownerTeamLogoLink" class="game-logos">
              <div class="game-team"> 
                <img class="item-logo" :src="calendar.ownerTeamLogoLink" alt=""/>
                <span class="game-team-name">{{ calendar.ownerTeamName }}</span> 
                <span class="game-team-city">{{ calendar.ownerTeamCity }}</span> 
              </div>
              <div class="game-separator">VS</div>
              <div class="game-team">
                  <img class="item-logo" :src="calendar.guestTeamLogoLink" alt=""/>
                  <span class="game-team-name">{{ calendar.guestTeamName }}</span> 
                  <span class="game-team-city">{{ calendar.guestTeamCity }}</span> 
              </div>
              <!-- <img v-if="!calendar.guestTeamLogoLink && (calendar.siteBigView == 0 || !calendar.siteBigView) && calendar.calendarLogoLink" class="item-logo" :src="calendar.calendarLogoLink" alt=""/> -->
              <!-- <img v-if="!calendar.guestTeamLogoLink && (calendar.siteBigView == 0 || !calendar.siteBigView) && !calendar.calendarLogoLink" class="item-logo-empty" src="@/_assets/redesign2020/img/test/empty-logo.svg" alt=""/> -->
              <!-- <img v-if="calendar.siteBigView == 1" class="item-background-picture" :src="calendar.siteBigViewLogoLink" alt=""/> -->
              <!-- <div class="item-name">{{ calendar.guestTeamName || calendar.fullName }}</div> -->
            </div> 
            <div v-if="!calendar.guestTeamLogoLink || !calendar.ownerTeamLogoLink" class="game-without-team">
              <div class="game-name">{{ calendar.guestTeamName || calendar.fullName }}</div>
            </div>
            <div class="game-action">
              <div class="division"></div>
              <a class="game-price">от {{ calendar.minPrice }} ₽</a>
              <!-- <button class="game-button"> -->
                    <router-link :to="{ name: 'games', params: { calendarId: calendar.id } }" class="game-button-text">купить</router-link>
              <!-- </button> -->
            </div>  
          </div>
        <!-- </router-link> -->
      </div>
      </div>
    </div>
    <div class="choice-ticket__section">
      <!-- <div class="choice-ticket__slider-downloads">
        <a class="choice-ticket__slider-link" href="/resources/files/HC_Sochi_games-2024-25.jpg" target="_blank">
          <svg>
            <use xlink:href="#ic_download"></use>
          </svg>
          Скачать расписание матчей
        </a>
      </div> -->
      <div class="choice-ticket__slider-navigation">
        <button class="choice-ticket__slider-prev" @click="prevSlide">
          <svg>
            <use xlink:href="#ic_arrow-next"></use>
          </svg>
        </button>
        <ul class="choice-ticket__slider-bullets">
          <li class="choice-ticket__slider-bullet active"><button type="button"></button></li>
          <li class="choice-ticket__slider-bullet"><button type="button"></button></li>
          <li class="choice-ticket__slider-bullet"><button type="button"></button></li>
        </ul>
        <button class="choice-ticket__slider-next" @click="nextSlide">
          <svg>
            <use xlink:href="#ic_arrow-next"></use>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventCards',
  props: {
    allCalendar: { type: Array },
    activeMonth: { type: String },
  },
  computed: {
    getCurrentCalendarByMonth() {
      return this.allCalendar?.find(calendar => calendar.monthText === this.activeMonth)?.webTypes.reduce((accumulator, webType) => {
        return accumulator.concat(webType.calendars).filter(calendar =>
          (calendar.algorithmSaleId === 510 || calendar.seasonLocationName === 'TICKETS') ||
          (calendar.algorithmSaleId === 520 && calendar.seasonLocationName === 'TICKETS') ||
          (calendar.algorithmSaleId === 520 && calendar.seasonLocationName === 'MINI') ||
          (calendar.algorithmSaleId === 530 && calendar.seasonLocationName === 'TICKETS') || calendar.algorithmSaleId === 516
        )
      }, []);
    },
  },
  methods: {
    prevSlide() {
      this.$refs.sliderWrapper.scrollTo({ left: this.$refs.sliderWrapper.scrollLeft - 500, behavior: 'smooth' });
    },
    nextSlide() {
      this.$refs.sliderWrapper.scrollTo({ left: this.$refs.sliderWrapper.scrollLeft + 500, behavior: 'smooth' });
    }
  }
}
</script>

<style lang="scss">
.choice-ticket {
  &__section {
    display: flex;
    justify-content: center;
  }

  &__slider-wrapper {
    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__spinner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.game-day {
  font-size: 20px;
  color: #212529;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  @media(max-width: 768px) {
    font-size: 18px;
  }
}

.game-date {
  color: var(--321, #006478);
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
}
.game-arena {
  color: var(--321, #006478);
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
}
.game-logos {
  display: flex; 
  justify-content: space-evenly;
  height: 166px;
  min-height: 166px;
  max-height: 166px;
}
.game-team {
  display: grid;
  width: 140px;
  @media(max-width: 768px) {
    width: 120px;
  }
}
.game-team-name {
  color: #000;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  line-height: 13px; /* 100% */
  letter-spacing: 0.39px;
  text-transform: uppercase; 
  @media(max-width: 768px) {
    font-size: 12px;
  }
}
.game-team-city {
  color: var(--321, #006478);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  @media(max-width: 768px) {
    font-size: 14px;
  }
}
.game-separator {
  width: 54px;
  color: rgb(0, 0, 0);
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  top: 50px;
  height: 50px;
  position: relative;
  @media(max-width: 768px) {
    font-size: 26px;
    width: 40px;
  }
}
.game-without-team {
  display: flex; 
  justify-content: space-evenly;
  height: 166px;
  min-height: 166px;
  max-height: 166px;
}
.game-name {
  color: #000;
  text-align: center;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0.39px;
  margin-bottom: auto;
  margin-top: auto;
  text-transform: uppercase;
  @media(max-width: 768px) {
    font-size: 18px;
  }
}
.division {
  opacity: 0.1;
  background: #000;
  width: 301px;
  height: 1px;
  margin-left: auto;
  margin-right: auto;
  @media(max-width: 768px) {
    width: 260px;
  }
  @media(max-width: 375px) {
    width: calc(100% - 40px);
    max-width: 100%;
  }
}
.game-action {
  display: grid;
  margin-top: 24px;
}
.game-price {
  color: var(--Black, #11181F);
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  line-height: 17px; /* 130.769% */ 
  opacity: 0.7;
  margin-top: 16px;
  margin-bottom: 8px;
}
.game-button {
  width: 269px;
  height: 50px;
  flex-shrink: 0; 
  border-radius: 7px;
  background: var(--32, #FABE10); 
  border: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
}
.game-button-text {
  color: var(--White, #FFF);
  background: var(--32, #FABE10); 
  border-radius: 7px;
  width: 269px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.42px;
  text-transform: uppercase; 
  line-height: 50px;
  @media(max-width: 375px) {
    width: calc(100% - 40px);
    max-width: 100%;
  }
}

.item-wrapper {
  cursor: pointer;
}
</style>

<style lang="scss" src="@/styles/EventCards.scss">
</style> 
