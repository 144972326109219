<template>
  <div class="header js-header animate" :class="{ 'show-menu': isShowMobileMenu, 'fixed': isScrollOverHeader}">
    <a href="/" class="header__logo">
      <div class="header__logo-img">
        <img :src="require('@/assets/images/logo-new-24.svg')" style="position: relative; top: 30px;" alt="Логотип ХК Сочи" loading="lazy" width="36" height="24">
      </div>
    </a>

    <div class="header__burger-menu js-burger-menu" tabindex="-1">
            
            <button type="button" class="js-close-menu close" tabindex="-1" @click="closeMenu()">
                <svg class="icon">
                    <use xlink:href="#ic_close"></use>
                </svg>
            </button>
            
            <ul class="header__menu-list">
                <li class="js-list-item  menu-dropdown" :class="{opened: isActive_Club}" @click="isActive_Club =!isActive_Club">
                    <button type="button" class="row-wrapper js-show-submenu" tabindex="-1">
                            <span>Клуб</span>
                            <span class="arrow">
                                <svg class="icon">
                                    <use xlink:href="#ic_chevron"></use>
                                </svg>
                            </span>
                    </button>
                            <ul>
                                            <li class="">
                                                <a href="https://www.hcsochi.ru/club/direction/" tabindex="-1" target="_blank">Руководство</a>
                                            </li>
                                            <li class="">
                                                <a href="https://www.hcsochi.ru/club/history/" tabindex="-1" target="_blank">История</a>
                                            </li>
                                            <li class="">
                                                <a href="http://school.hcsochi.ru/" tabindex="-1" target="_blank">Хоккейная школа</a>
                                            </li>
                                            <li class="">
                                                <a href="https://www.hcsochi.ru/club/partner/" tabindex="-1" target="_blank">Спонсоры и партнеры</a>
                                            </li>
                                            <li class="">
                                                <a href="https://www.hcsochi.ru/club/cooperation/" tabindex="-1" target="_blank">Сотрудничество</a>
                                            </li>
                                            <li class="">
                                                <a href="https://www.hcsochi.ru/club/arena/" tabindex="-1" target="_blank">ДС «Большой»</a>
                                            </li>
                                            <li class="">
                                                <a href="https://www.hcsochi.ru/club/contacts/" tabindex="-1" target="_blank">Контакты</a>
                                            </li>
                            </ul>
                </li>
                <li class="js-list-item  menu-dropdown" :class="{opened: isActive_Team}" @click="isActive_Team =!isActive_Team">
                    <button type="button" class="row-wrapper js-show-submenu" tabindex="-1">
                                        <span>Команда</span>
                                        <span class="arrow">
                                            <svg class="icon">
                                                <use xlink:href="#ic_chevron"></use>
                                            </svg>
                                        </span>
                    </button>
                            <ul>
                                            <li class="">
                                                <a href="https://www.hcsochi.ru/team/trainer/" tabindex="-1" target="_blank">Тренерский штаб</a>
                                            </li>
                                            <li class="">
                                                <a href="https://www.hcsochi.ru/team/" tabindex="-1" target="_blank">Игроки</a>
                                            </li>
                                            <li class="">
                                                <a href="https://www.hcsochi.ru/team/staff/" tabindex="-1" target="_blank">Персонал</a>
                                            </li>
                                            <li class="">
                                                <a href="https://www.hcsochi.ru/team/mascot/" tabindex="-1" target="_blank">Талисман</a>
                                            </li>
                                            
                            </ul>
                </li>
                <li class="js-list-item  menu-dropdown" :class="{opened: isActive_Matches}" @click="isActive_Matches =!isActive_Matches">
                    <button type="button" class="row-wrapper js-show-submenu" tabindex="-1">
                                        <span>Матчи</span>
                                        <span class="arrow">
                                            <svg class="icon">
                                                <use xlink:href="#ic_chevron"></use>
                                            </svg>
                                        </span>
                    </button>
                            <ul>
                                            <li class="">
                                                <a href="https://www.hcsochi.ru/games/" tabindex="-1" target="_blank">Календарь игр</a>
                                            </li>
                                            <li class="">
                                                <a href="https://www.hcsochi.ru/games/statistics/" tabindex="-1" target="_blank">Статистика</a>
                                            </li>
                            </ul>
                </li>
                <li class="js-list-item  menu-dropdown" :class="{opened: isActive_SochiClub}" @click="isActive_SochiClub =!isActive_SochiClub">
                    <button type="button" class="row-wrapper js-show-submenu" tabindex="-1">
                                        <span>Сочи клаб</span>
                                        <span class="arrow">
                                            <svg class="icon">
                                                <use xlink:href="#ic_chevron"></use>
                                            </svg>
                                        </span>
                    </button>
                            <ul>
                                            <li class="">
                                                <a href="https://club.hcsochi.ru/" tabindex="-1" target="_blank">Программа лояльности</a>
                                            </li>
                                            <li class="">
                                                <a href="https://www.hcsochi.ru/fan-zone/leo-i-sochi-queens/" tabindex="-1" target="_blank">Sochi Queens</a>
                                            </li>
                                            <li class="">
                                                <a href="https://www.hcsochi.ru/fan-zone/" tabindex="-1" target="_blank">Фан-сектор</a>
                                            </li>
                                            <li class="">
                                                <a href="https://www.hcsochi.ru/fan-zone/stickers/" tabindex="-1" target="_blank">Стикеры</a>
                                            </li>
                            </ul>
                </li>
                <li class="js-list-item  menu-dropdown" :class="{opened: isActive_School}" @click="isActive_School =!isActive_School">
                    <button type="button" class="row-wrapper js-show-submenu" tabindex="-1">
                                        <span>Школа</span>
                                        <span class="arrow">
                                            <svg class="icon">
                                                <use xlink:href="#ic_chevron"></use>
                                            </svg>
                                        </span>
                    </button>
                            <ul>
                                            <li class="">
                                                <a href="https://www.hcsochi.ru/school/" tabindex="-1" target="_blank">Школа</a>
                                            </li>
                                            <li class="">
                                                <a href="https://www.hcsochi.ru/school/coach/" tabindex="-1" target="_blank">Тренерский штаб</a>
                                            </li>
                                            <li class="">
                                                <a href="https://www.hcsochi.ru/school/faq/" tabindex="-1" target="_blank">Часто задаваемые вопросы</a>
                                            </li>
                                            <li class="">
                                                <a href="https://www.hcsochi.ru/school/novyy-nabor/" tabindex="-1" target="_blank">Новый набор</a>
                                            </li>
                            </ul>
                </li>
                <li class="js-list-item  menu-dropdown" :class="{opened: isActive_Media}" @click="isActive_Media =!isActive_Media">
                    <button type="button" class="row-wrapper js-show-submenu" tabindex="-1">
                                        <span>Медиа</span>
                                        <span class="arrow">
                                            <svg class="icon">
                                                <use xlink:href="#ic_chevron"></use>
                                            </svg>
                                        </span>
                    </button>
                            <ul>
                                            <li class="">
                                                <a href="https://www.hcsochi.ru/media/news/" tabindex="-1" target="_blank">Новости</a>
                                            </li>
                                            <li class="">
                                                <a href="https://www.hcsochi.ru/media/photo/" tabindex="-1" target="_blank">Фото</a>
                                            </li>
                                            <li class="">
                                                <a href="https://www.hcsochi.ru/media/video/" tabindex="-1" target="_blank">Видео</a>
                                            </li>
                                            <li class="">
                                                <a href="https://www.hcsochi.ru/media/programs/" tabindex="-1" target="_blank">Программки</a>
                                            </li>
                                            <li class="">
                                                <a href="https://www.hcsochi.ru/media/accreditation/" tabindex="-1" target="_blank">Аккредитация</a>
                                            </li>
                            </ul>
                </li>
                <li class="js-list-item ">
                    <button type="button" class="row-wrapper js-show-submenu" tabindex="-1">
                            <a href="https://hcsochi.ru/" tabindex="-1">ХК Сочи</a>
                    </button>
                </li>
                <!-- <li class="js-list-item ">
                    <button type="button" class="row-wrapper js-show-submenu" tabindex="-1">
                            <a href="https://vip.hcsochi.ru/" tabindex="-1">VIP</a>
                    </button>
                </li> -->
                <li class="js-list-item ">
                    <button type="button" class="row-wrapper js-show-submenu" tabindex="-1">
                            <a href="https://shop.hcsochi.ru/?_ga=2.67423505.1848622713.1688640549-27064640.1664910840" tabindex="-1">Атрибутика</a>
                    </button>
                </li>
                <div v-if="this.userInfoId">
                    <button class="js-list-item-login "
                        @click="logOff">
                        <span>Выйти</span>
                    </button>
                </div>
                <form v-else class="" type="submit" method="GET" action="/webapi/auth/login">
                    <button class="js-list-item-login ">
                        <span>Войти</span>
                    </button>
                </form>
                <li v-if="this.userInfoId" class="js-list-item" style="padding-left: 32px; font-size: 12px; text-transform: unset;"> 
                    E-mail: {{ this.userInfo.userEmail }}
                </li>
                <li v-if="this.userInfoId && (this.userInfo.activeBalance === 0 || !this.userInfo.activeBalance)" class="js-list-item" style="padding-left: 32px; font-size: 12px; text-transform: unset;"> 
                    Баланс: {{ this.userInfo.activeBalance }} бонусов
                </li>
                <li v-if="this.userInfoId && this.userInfo.activeBalance === 1" class="js-list-item" style="padding-left: 32px; font-size: 12px; text-transform: unset;"> 
                    Баланс: {{ this.userInfo.activeBalance }} бонус
                </li>
                <li v-if="this.userInfoId && this.userInfo.activeBalance >= 2  && this.userInfo.activeBalance <= 5" class="js-list-item" style="padding-left: 32px; font-size: 12px; text-transform: unset;"> 
                    Баланс: {{ this.userInfo.activeBalance }} бонуса
                </li>
                <li v-if="this.userInfoId  && this.userInfo.activeBalance >= 6" class="js-list-item" style="padding-left: 32px; font-size: 12px; text-transform: unset;"> 
                    Баланс: {{ this.userInfo.activeBalance }} бонусов
                </li>
            </ul>

            <div class="header__socials">
                <a href="https://vk.com/hcsochi" target="_blank" tabindex="-1" class="header__socials-link header__socials-link--vk">
                    <svg class="vk">
                        <use xlink:href="#ic_vk"></use>
                    </svg>
                </a>
                <a href="https://t.me/sochihc" target="_blank" tabindex="-1" class="header__socials-link header__socials-link--fb">
                    <svg class="fb">
                        <use xlink:href="#ic_telegram"></use>
                    </svg>
                </a>
                <!-- <a href="https://twitter.com/HCSOCHI" target="_blank" tabindex="-1" class="header__socials-link header__socials-link--twitter">
                    <svg class="twitter">
                        <use xlink:href="#ic_twitter"></use>
                    </svg>
                </a> -->
                <a href="https://www.youtube.com/user/sochihc" target="_blank" tabindex="-1" class="header__socials-link header__socials-link--ytb">
                    <svg class="youtube">
                        <use xlink:href="#ic_youtube"></use>
                    </svg>
                </a>
                <a href="https://play.google.com/store/apps/details?id=ru.hcsochi" target="_blank" tabindex="-1" class="header__socials-link header__socials-link--inst">
                    <svg class="inst">
                        <use xlink:href="#ic_googleplay"></use>
                    </svg>
                </a>
                <a href="https://apps.apple.com/ru/app/%D1%85%D0%BA-%D1%81%D0%BE%D1%87%D0%B8/id1661551981" target="_blank" tabindex="-1" class="header__socials-link header__socials-link--inst">
                    <svg class="inst">
                        <use xlink:href="#ic_app-store"></use>
                    </svg>
                </a>
               
            </div>
        </div>

        <div class="header__menu">

            <div class="header__menu-top">
                <div class="header__socials">
                    <a href="https://vk.com/hcsochi" target="_blank" class="header__socials-link header__socials-link--vk">
                        <svg class="vk">
                            <use xlink:href="#ic_vk"></use>
                        </svg>
                    </a>
                    <!-- <a href="https://t.me/sochihc" target="_blank" class="header__socials-link header__socials-link--twitter">
                        <svg class="twitter">
                            <use xlink:href="#ic_telegram"></use>
                        </svg>
                    </a> -->
                    <!-- <a href="https://twitter.com/HCSOCHI" target="_blank" class="header__socials-link header__socials-link--twitter">
                        <svg class="twitter">
                            <use xlink:href="#ic_twitter"></use>
                        </svg>
                    </a> -->
                    <a href="https://www.youtube.com/user/sochihc" target="_blank" class="header__socials-link header__socials-link--ytb">
                        <svg class="youtube">
                            <use xlink:href="#ic_youtube"></use>
                        </svg>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=ru.hcsochi" target="_blank" tabindex="-1" class="header__socials-link header__socials-link--inst">
                        <svg class="inst">
                            <use xlink:href="#ic_googleplay"></use>
                        </svg>
                    </a>
                    <a href="https://apps.apple.com/ru/app/%D1%85%D0%BA-%D1%81%D0%BE%D1%87%D0%B8/id1661551981" target="_blank" tabindex="-1" class="header__socials-link header__socials-link--inst">
                        <svg class="inst">
                            <use xlink:href="#ic_app-store"></use>
                        </svg>
                    </a>
           
                </div>

            </div>

            <div class="header__menu-bottom">
                
                <ul class="header__menu-list">
                    <li class=" menu-dropdown">
                            <span>Клуб</span>
            
            
                            <ul>
                        <div class="gap"></div>
                                            <li class=" js-top-submenu-item" data-url="https://www.hcsochi.ru/club/direction/"><a href="https://www.hcsochi.ru/club/direction/" target="_blank">Руководство</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://www.hcsochi.ru/club/history/"><a href="https://www.hcsochi.ru/club/history/" target="_blank">История</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://www.hcsochi.ru/club/partner/"><a href="https://www.hcsochi.ru/club/partner/" target="_blank">Спонсоры и партнеры</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://www.hcsochi.ru/club/cooperation/"><a href="https://www.hcsochi.ru/club/cooperation/" target="_blank">Сотрудничество</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://www.hcsochi.ru/club/arena/"><a href="https://www.hcsochi.ru/club/arena/" target="_blank">ДС «Большой»</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://www.hcsochi.ru/club/contacts/"><a href="https://www.hcsochi.ru/club/contacts/" target="_blank">Контакты</a></li>
                                    </ul>
                    </li>
                    <li class=" menu-dropdown">
                            <span>Команда</span>
            
            
                        <ul>
                            <div class="gap"></div>
                                            <li class=" js-top-submenu-item" data-url="https://www.hcsochi.ru/team/trainer/"><a href="https://www.hcsochi.ru/team/trainer/" target="_blank">Тренерский штаб</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://www.hcsochi.ru/team/"><a href="https://www.hcsochi.ru/team/" target="_blank">Игроки</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://www.hcsochi.ru/team/staff/"><a href="https://www.hcsochi.ru/team/staff/" target="_blank">Персонал</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://www.hcsochi.ru/team/mascot/"><a href="https://www.hcsochi.ru/team/mascot/" target="_blank">Талисман</a></li>
                        </ul>
                    </li>
                    <li class=" menu-dropdown">
                        <span>Матчи</span>
                        <ul>
                            <div class="gap"></div>
                                            <li class=" js-top-submenu-item" data-url="https://www.hcsochi.ru/games/"><a href="https://www.hcsochi.ru/games/" target="_blank">Календарь игр</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://www.hcsochi.ru/games/statistics/"><a href="https://www.hcsochi.ru/games/statistics/" target="_blank">Статистика</a></li>
                        </ul>
                    </li>
                    <li class=" menu-dropdown">
                        <span>Сочи клаб</span>
                        <ul>
                            <div class="gap"></div>
                                            <li class=" js-top-submenu-item" data-url="https://club.hcsochi.ru/"><a href="https://club.hcsochi.ru/" target="_blank">Программа лояльности</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://www.hcsochi.ru/fan-zone/leo-i-sochi-queens/"><a href="https://www.hcsochi.ru/fan-zone/leo-i-sochi-queens/" target="_blank">Sochi Queens</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://www.hcsochi.ru/fan-zone/"><a href="https://www.hcsochi.ru/fan-zone/" target="_blank">Фан-сектор</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://www.hcsochi.ru/fan-zone/stickers/"><a href="https://www.hcsochi.ru/fan-zone/stickers/" target="_blank">Стикеры</a></li>
                        </ul>
                    </li>
                    <li class=" menu-dropdown">
                            <span>Школа</span>
                            <ul>
                        <div class="gap"></div>
                                            <li class=" js-top-submenu-item" data-url="https://www.hcsochi.ru/school/"><a href="https://www.hcsochi.ru/school/" target="_blank">Школа</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://www.hcsochi.ru/school/coach/"><a href="https://www.hcsochi.ru/school/coach/" target="_blank">Тренерский штаб</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://www.hcsochi.ru/school/faq/"><a href="https://www.hcsochi.ru/school/faq/" target="_blank">Часто задаваемые вопросы</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://www.hcsochi.ru/school/novyy-nabor/"><a href="https://www.hcsochi.ru/school/novyy-nabor/" target="_blank">Новый набор</a></li>
                                    </ul>
                    </li>
                    <li class=" menu-dropdown">
                            <span>Медиа</span>
            
            
                            <ul>
                                <div class="gap"></div>
                                            <li class=" js-top-submenu-item" data-url="https://www.hcsochi.ru/media/news/"><a href="https://www.hcsochi.ru/media/news/" target="_blank">Новости</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://www.hcsochi.ru/media/photo/"><a href="https://www.hcsochi.ru/media/photo/" target="_blank">Фото</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://www.hcsochi.ru/media/video/"><a href="https://www.hcsochi.ru/media/video/" target="_blank">Видео</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://www.hcsochi.ru/media/programs/"><a href="https://www.hcsochi.ru/media/programs/" target="_blank">Программки</a></li>
                                            <li class=" js-top-submenu-item" data-url="https://www.hcsochi.ru/media/accreditation/"><a href="https://www.hcsochi.ru/media/accreditation/" target="_blank">Аккредитация</a></li>
                            </ul>
                    </li>
                </ul>
                

            <div class="header__search-button-off"></div>
            <ul class="header__info-links">
                <li class="header__info-link">
                    <a class="header__info-styled-link" href="https://hcsochi.ru/" target="_blank">
                        <img class="header__info-link-img" :src="require('@/assets/images/globus.svg')"/>
                        <span>ХК Сочи</span>
                    </a>
                </li>
                <!-- <li class="header__info-link">
                    <a class="header__info-styled-link" href="https://vip.hcsochi.ru/" target="_blank">
                        <img :src="require('@/assets/images/stul.svg')"/>
                        <span>VIP</span>
                    </a>
                </li> -->
                <li class="header__info-link">
                    <a class="header__info-styled-link" href="https://shop.hcsochi.ru/?_ga=2.67423505.1848622713.1688640549-27064640.1664910840" target="_blank">
                        <img :src="require('@/assets/images/sumka.svg')"/>
                        <span>Атрибутика</span>
                    </a>
                </li>
                <div v-if="this.userInfoId">
                    <button class="header__info-link"
                            @click="logOff">
                        <img :src="require('@/assets/images/dver.svg')"/>
                        <span>Выйти</span>
                    </button>
                </div>
                <form v-else type="submit" method="GET" action="/webapi/auth/login">
                    <button class="header__info-link" style="padding: 0;">
                        <img :src="require('@/assets/images/enter.png')"/>
                        <span>Войти</span>
                    </button>
                </form>
                
            </ul>
                    <!-- <div class="header__user-panel header__user-panel--bottom">
                        <button v-if="this.userEmail" type="button" class="header__authorization" style="z-index: 1;">
                                <svg class="icon">
                                    <use xlink:href="#ic_authorization"></use>
                                </svg>
                                <span>{{ this.userEmail }}</span>
                        </button>
                        <button v-else type="button" class="header__authorization js-popup-auth-open" @click="loginHandler">
                            <svg class="icon">
                                <use xlink:href="#ic_authorization"></use>
                            </svg>
                        </button>
                        <ul v-if="this.userEmail" class="user-menu">
                          <li class="user-menu__item"><a href="https://hcsochi.ru/profile/" tabindex="-1">Личный кабинет</a></li> 
                          <li class="user-menu__item"><a href="https://hcsochi.ru/profile/orders/">Заказы</a></li> 
                          <li class="user-menu__item" @click="logOff"><a>Выйти</a></li>  
                        </ul> 
                    </div> -->
            </div>

        </div>

        <div class="header__mobile-menu">
            <a href="https://hcsochi.ru/games/">Матчи</a>
            <a href="https://tickets.hcsochi.ru/" target="_blank">Билеты</a>
            <button type="button" class="js-hamburger-btn" @click="isShowMobileMenu = !isShowMobileMenu">   
                <div class="burger"><span></span></div>
                Меню            </button>
        </div>
  </div>
</template>

<script>
import { authorizationMapper } from '@/store/modules/authorization';

export default {
  name: 'Header',
  data() {
    return {
      isShowMobileMenu: false,
      isActive_Club: false,
      isActive_Matches: false,
      isActive_Team: false,
      isActive_Media: false,
      isActive_SochiClub: false,
      isActive_School: false,
      isScrollOverHeader: false
    }
  },
  mounted() {
    // Setup the event listener and execute it once in case we are already scrolled
    window.addEventListener('scroll', this.scrollHandler);
    this.scrollHandler();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler);
  },
  methods: {
    ...authorizationMapper.mapActions(['logout']),
    loginHandler() {
      fetch("/webapi/auth/login", {headers: {
        // "Access-Control-Allow-Origin": "https://test-tickets.hcsochi.ru"
      }})
    },
    async logOff() {
      await this.logout();
    },
    closeMenu() {
      this.isShowMobileMenu = false;  
      document.body.style.overflow = 'auto';
    },
    Burger_menu: function() {
      this.isActive = !this.isActive;
    },
    scrollHandler() {
      this.isScrollOverHeader = window.pageYOffset > 80;
    }
    
  },
  computed: {
    ...authorizationMapper.mapGetters(['userInfo']),
    userEmail() {
      return this.userInfo?.userEmail;
    },
    userInfoId() {
      return this.userInfo?.userId;
    },
  },
  watch: {
      isShowMobileMenu() {
        if (this.isShowMobileMenu) {
          document.body.style.overflow = 'hidden';
          const burgerMenu = document.getElementsByClassName('header__burger-menu');
          for (let i = 0; i < burgerMenu.length; i++) {
            burgerMenu[i].style.overflow = 'auto';
          }
        }
      },
  }
}
</script>
