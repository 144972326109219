<template>
  <div class="success-modal">
    <div class="success-modal__wrapper">
      <p class="success-modal__title">Билет будет отправлен на вашу почту</p>
      
      <div class="success-modal__actions">
          <button
            class="yellow-button"
            style="min-width: 200px;"
            @click="closeSuccessModal"
          >
            <span class="success-modal__come-in-title">Закрыть</span>
          </button>
      </div>
    </div>
    <div class="overlay" @click="closeSuccessModal"></div>
  </div>
</template>

<script>
export default {
  name: 'SuccessModal',
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
    closeSuccessModal() {
      this.$emit('closeModal', false);
    },
  },
}
</script>

<style lang="scss" src="@/styles/SuccessModal.scss">
</style>
