<template>
  <div class="authorization-modal">
    <div class="authorization-modal__wrapper">
      <p class="authorization-modal__title">Необходима авторизация</p>
      <div class="authorization-modal__logo">
        <img :src="require('@/assets/images/logo-new-24.svg')" style="width: 188px;"/>
      </div>
      <div class="authorization-modal__actions">
        <form type="submit" method="GET" action="/webapi/auth/login">
          <button
              class="yellow-button"
              style="min-width: 200px;"
              :disabled="isLoading"
              :class="{'loading': isLoading}"
          >
            <span class="authorization-modal__come-in-title">Войти</span>
            <span class="loader"></span>
          </button>
        </form>
      </div>
    </div>
    <div class="overlay" @click="closeAuthorizationModal"></div>
  </div>
</template>

<script>
import {authorizationMapper} from '@/store/modules/authorization';
import { calendarMapper } from '@/store/modules/calendar';

export default {
  name: 'AuthorizationModal',
  data() {
    return {
      username: '',
      password: '',
      isLoading: false,
      error: null
    }
  },
  computed: {
    ...authorizationMapper.mapGetters(['loginInfo', 'userInfo']),
  },
  mounted() {
    const body = document.body;
    body.style.height = '100vh';
    body.style.overflowY = 'hidden';
  },
  beforeDestroy() {
    const body = document.body;
    body.style.height = '100%';
    body.style.overflowY = 'auto';
  },
  methods: {
    ...authorizationMapper.mapActions(['login', 'getPrincipalInfo']),
    ...calendarMapper.mapActions(['getReservedSeatsList']),
    closeAuthorizationModal() {
      this.$emit('closeAuthorizationModal', false, false);
    },
    async authorize(password, username) {
      this.isLoading = true;
      this.error = null;
      try {
        await this.login({password, username});
        this.$emit('processSeatClick');
        this.$emit('closeAuthorizationModal', false, true);
        await this.getPrincipalInfo();
      } catch (e) {
        this.error = e.errorMessage;
      } finally {
        this.isLoading = false;
      }

    },
  },
}
</script>

<style lang="scss" src="@/styles/AuthorizationModal.scss">
</style>
