<template>
  <div id="app">
    <AuthorizationModal 
      v-if="isAuthorizationModalShown"
      @closeAuthorizationModal="closeAuthorizationModal" 
    />
    <Header @showAuthorizationModal="showAuthorizationModal" />
    <SubHeader />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import { authorizationMapper } from '@/store/modules/authorization';

import Header from './components/header/Header.vue';
import SubHeader from './components/header/SubHeader.vue';
import Footer from './components/footer/Footer.vue';
import AuthorizationModal from "./components/header/AuthorizationModal.vue";

export default {
  name: 'App',
  components: {
    Header,
    SubHeader,
    AuthorizationModal,
    Footer
  },
  data() {
    return {
      isAuthorizationModalShown: false,
    }
  },
  async mounted() {
    await this.getUserInfo();
    await this.getPrincipalInfo();
  },
  methods: {
    ...authorizationMapper.mapActions(['getUserInfo', 'getPrincipalInfo']),
    showAuthorizationModal(isShowModal) {
      this.isAuthorizationModalShown = isShowModal;
    },
    closeAuthorizationModal(isShowModal) {
      this.isAuthorizationModalShown = isShowModal;
    },
  },
  computed: {
    ...authorizationMapper.mapGetters(['userInfo', 'principalInfo']),
  },
}
</script>
