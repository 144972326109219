import axios from 'axios';

// const api = {
//   url: `https://test-tickets.hcsochi.ru`,
// };

export default {

  async orderpaybyemail({orderId, email}) {
    try {
      const url = `/webapi/orders/pay-by-email`;
      const response = await axios.put(url, {"orderId":orderId,"email":email});

      return response.data;
    } catch(error) {
      throw error.response;
    }
  }
};


