import { Getters, Mutations, Module, Actions, createMapper } from 'vuex-smart-module';

import authorizationApi from '@/services/authorization.api';

export class AuthorizationState {
  userInfo;
  principalInfo;
  loginInfo;
  simpleAuthInfo;
}

export class AuthorizationGetters extends Getters {
  get userInfo() {
    return this.state.userInfo;
  }

  get principalInfo() {
    return this.state.principalInfo;
  }

  get loginInfo() {
    return this.state.loginInfo;
  }

  get simpleAuthInfo() {
    return this.state.simpleAuthInfo;
  }
}

export class AuthorizationMutations extends Mutations {
  setUserInfo(userInfo) {
    this.state.userInfo = userInfo;
  }

  setPrincipalInfo(principalInfo) {
    this.state.principalInfo = principalInfo;
  }

  setLoginInfo(loginInfo) {
    this.state.loginInfo = loginInfo;
  }

  setSimpleAuthInfo(simpleAuthInfo) {
    this.state.simpleAuthInfo = simpleAuthInfo;
  }
}

export class AuthorizationActions extends Actions {
  async getUserInfo() {
    try {
      const response = await authorizationApi.getUserInfo();
      this.commit('setUserInfo', response);
    } catch ({ message }) {
      this.commit('setUserInfo', []);
    }
  }

  async getPrincipalInfo() {
    try {
      const response = await authorizationApi.getPrincipalInfo();
      this.commit('setPrincipalInfo', response);
    } catch ({ message }) {
      this.commit('setPrincipalInfo', []);
    }
  }

	async login(userInfo) {
		const response = await authorizationApi.login(userInfo);
		this.commit('setUserInfo', response);
	}

  async logout() {
    try {
      await authorizationApi.logout();
      this.commit('setUserInfo', []);
    } catch ({ message }) {
		this.commit('setUserInfo', []);
    }
  }

  async simpleAuth(simpleAuthInfo) {
		const response = await authorizationApi.simpleAuth(simpleAuthInfo);
		this.commit('setSimpleAuthInfo', response);
	}

  async confirmAccount(simpleAuthInfo) {
		const response = await authorizationApi.confirmAccount(simpleAuthInfo);
		this.commit('setSimpleAuthInfo', response);
	}

  async simpleAuthLogout() {
    try {
      await authorizationApi.simpleAuthLogout();
      this.commit('setSimpleAuthInfo', []);
    } catch ({ message }) {
		this.commit('setSimpleAuthInfo', []);
    }
  }
}

export const authorizationModule = new Module({
  state: AuthorizationState,
  getters: AuthorizationGetters,
  mutations: AuthorizationMutations,
  actions: AuthorizationActions,
});

export const authorizationMapper = createMapper(authorizationModule);

export default authorizationModule;
