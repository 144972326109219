import axios from 'axios';

// const api = {
//   url: `https://test-tickets.hcsochi.ru`,
// };

export default {
  async getAllCalendar() {
    try {
      const url = `/webapi/calendars/available/list/grouped`;
      const response = await axios.post(url, {
        "isSeason": -1,
      });

      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  async getAvailCalendarList() {
    try {
      const url = `/webapi/calendars/available/list`;
      const response = await axios.post(url, {
        "isSeason": 1,
      });

      return response.data;
    } catch (error) {
      throw error.response;
    }
  },


  // Same API for getting Mini Abonement By Id
  async getCalendarListById(calendarId) {
    try {
      const url = `/webapi/calendars/available/list`;
      const response = await axios.post(url, {
        "calendarId": calendarId,
      });

      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  async getCalendarById(calendarId) {
    try {
      const url = `/webapi/calendars/${calendarId}`;
      const response = await axios.post(url, {});

      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  async getSeatsSvg(calendarId) {
    try {
      const url = `/webapi/sectors/${calendarId}/schema/svg`;
      const response = await axios.get(url);

      return response.data;
    } catch {
      throw new Error('Failed to get calendar seats scheme. Please try again later.');
    }
  },

  async getAvailableSectorsList({calendarId, searchFilter}) {
    try {
      const url = `/webapi/sectors/${calendarId}/available/list`;
      if (Object.keys(searchFilter).length != 0) {
      const response = await axios.post(url, {
          "seasonIds": searchFilter
        });
        return response.data;
      }
      else {
        const response = await axios.post(url, {
          "searchFilter": searchFilter
        });
        return response.data;
      }
      

      //return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  async getAvailableSeatList(calendarId, sectorId) {
    try {
      const url = `/webapi/seats/${calendarId}/${sectorId}/available/list`;
      const response = await axios.post(url, {});

      return response.data;
    } catch {
      throw new Error('Failed to get available sectors list. Please try again later.');
    }
  },

  async getSchemeBySectorId(calendarId, sectorId, seasonIds) {
    try {
      let url;
      if (seasonIds.length > 0) {
        url = `/webapi/seats/schema/${calendarId}/${sectorId}/build?${seasonIds.map(seasonId => `seasonIds=${seasonId}`).join('&')}`;
      } else {
        url = `/webapi/seats/schema/${calendarId}/${sectorId}/build`
      }
      const response = await axios.get(url);

      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  async getReservedSeatsList() {
    try {
      const url = `/webapi/seats/reserved/list`;
      const response = await axios.get(url);

      return response.data;
    } catch {
      throw new Error('Failed to get list of reserved seats. Please try again later.');
    }
  },

  async reserveSeat({calendarId, reserveInfo}) {
    try {
      const url = `/webapi/seats/${calendarId}/reserve`;
      const response = await axios.put(url, reserveInfo);

      return response.data;
    } catch(error) {
      throw error.response;
    }
  },


	async unreserveSeat({calendarId, unreserveInfo}) {
		try {
			const url = `/webapi/seats/${calendarId}/unreserve`;
			const response = await axios.put(url, unreserveInfo);

			return response.data;
		} catch (error) {
			throw new Error(error.response.data.errorMessage);
		}
	},

	async toggleSeatType({calendarId, seatId, isChild}) {
		try {
			const url = `/webapi/seats/${calendarId}/modify/${seatId}/${isChild ? 'adult' : 'child'}`;
			const response = await axios.put(url);

			return response.data;
		} catch (error) {
			throw new Error(error.response.data.errorMessage);
		}
	},

	async getBonuses() {
		try {
			const url = `/webapi/orders/loyalty/bonus`;
			const response = await axios.get(url);

			return response.data;
		} catch {
			throw new Error('Failed to get bonuses. Please try again later.');
		}
	},

	async checkPromoCode(calendarId, promocode) {
		try {
			const url = `/webapi/orders/loyalty/promocode?calendarId=${calendarId}&promocode=${promocode}`;
			const response = await axios.get(url);

			return response.data;
		} catch (e) {
			throw e.response.data;
		}
	},

	async createOrder(model) {
		try {
			const url = `/webapi/orders/create`;
			const response = await axios.post(url, model);

			return response.data;
		} catch (e) {
			throw e.response.data;
		}
	}
};
