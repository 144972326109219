<template>
  <div class="limit-modal">
    <div class="limit-modal__wrapper">
      <p class="limit-modal__title">Превышен лимит количества выбранных мест</p>
      <p class="limit-modal__subtitle"></p>
      <div class="limit-modal__separator"></div>
      <div class="limit-modal__actions">
          <button
            class="yellow-button"
            style="min-width: 200px;"
            @click="closeLimitModal"
          >
            <span class="limit-modal__come-in-title">НАЗАД</span>
          </button>
      </div>
    </div>
    <div class="overlay" @click="closeLimitModal"></div>
  </div>
</template>

<script>
export default {
  name: 'LimitModal',
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
    const body = document.body;
    body.style.height = '100vh';
    body.style.overflowY = 'hidden';
  },
  beforeDestroy() {
    const body = document.body;
    body.style.height = '100%';
    body.style.overflowY = 'auto';
  },
  methods: {
    closeLimitModal() {
      this.$emit('closeLimitModal', false, false);
    },
  },
}
</script>

<style lang="scss" src="@/styles/LimitModal.scss">
</style>
