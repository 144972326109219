<template>
  
   

  <div class="all" v-if="seatInOrder">
    <div class="success-order-pay">
      <h1 v-if="seatInOrder.payStatus === 'PAID'" class="success-order-pay__title">Ваш заказ {{this.$route.params.orderId}} успешно оплачен</h1>
      <h1 v-if="seatInOrder.payStatus != 'PAID' && seatInOrder.payStatus" class="success-order-pay__title">Ваш заказ {{this.$route.params.orderId}} не оплачен</h1>
      <h1 v-if="!seatInOrder.payStatus" class="success-order-pay__title">Заказ {{this.$route.params.orderId}} не найден</h1>
      <div v-if="seatInOrder.payStatus === 'PAID'" class="success-order-pay__tickets-info">
        
        <p v-if="seatInOrder.payStatus === 'PAID' && seatInOrder.orderPaymentDate" class="success-order-pay__tickets-info-subtitle-16">Дата и время оплаты: 
          {{ moment(seatInOrder.orderPaymentDate,'DD.MM.YYYY').locale('ru').format('DD MMMM YYYY') }} г. в 
          {{ moment(seatInOrder.orderPaymentDate,'DD.MM.YYYY HH:mm').locale('ru').format('HH:mm') }}
        </p>
        <p v-if="seatInOrder.payStatus === 'PAID' && !seatInOrder.orderPaymentDate" class="success-order-pay__tickets-info-subtitle-16">Дата и время заказа: 
          {{ moment(seatInOrder.orderCreateDate,'DD.MM.YYYY').locale('ru').format('DD MMMM YYYY') }} г. в 
          {{ moment(seatInOrder.orderCreateDate,'DD.MM.YYYY HH:mm').locale('ru').format('HH:mm') }}
        </p>

        <p v-if="(isTicket || isParking) && !isSeasonTicket && seatInOrder.status === 'ISSUED' && this.seatInOrder.orderSeats.length === 1" class="success-order-pay__tickets-info-title">Мы отправили билет и квитанцию об оплате на эл. почту {{seatInOrder.email}}</p>
        <p v-if="!isTicket && !isParking && isSeasonTicket && seatInOrder.status === 'ISSUED' && this.seatInOrder.orderSeats.length === 1" class="success-order-pay__tickets-info-title">Мы отправили абонемент и квитанцию об оплате на эл. почту {{seatInOrder.email}}</p>
        <p v-if="isTicket && !isParking && !isSeasonTicket && this.seatInOrder.orderSeats.length === 1" class="success-order-pay__tickets-info-subtitle-16">
          Билет также хранится в вашем <a class="success-order-pay__section-link" href="/orders">личном кабинете.</a> 
        </p>
        <p v-if="!isTicket && isParking && !isSeasonTicket && this.seatInOrder.orderSeats.length === 1" class="success-order-pay__tickets-info-subtitle-16">
          Билет также хранится в вашем <a class="success-order-pay__section-link" href="/orders">личном кабинете.</a> 
        </p>
        <p v-if="!isTicket && !isParking && isSeasonTicket && this.seatInOrder.orderSeats.length === 1" class="success-order-pay__tickets-info-subtitle-16">
          Абонемент также хранится в вашем <a class="success-order-pay__section-link" href="/orders">личном кабинете.</a> 
        </p>
        <p v-if="(isTicket || isParking) && isSeasonTicket && this.seatInOrder.orderSeats.length === 1" class="success-order-pay__tickets-info-subtitle-16">
          Билет и абонемент также хранится в вашем <a class="success-order-pay__section-link" href="/orders">личном кабинете.</a> 
        </p>

        <p v-if="(isTicket || isParking) && !isSeasonTicket && seatInOrder.status === 'ISSUED' && this.seatInOrder.orderSeats.length > 1" class="success-order-pay__tickets-info-title">Мы отправили билеты и квитанцию об оплате на эл. почту {{seatInOrder.email}}</p>
        <p v-if="!isTicket && !isParking && isSeasonTicket && seatInOrder.status === 'ISSUED' && this.seatInOrder.orderSeats.length > 1" class="success-order-pay__tickets-info-title">Мы отправили абонементы и квитанцию об оплате на эл. почту {{seatInOrder.email}}</p>
        <p v-if="(isTicket || isParking) && isSeasonTicket && seatInOrder.status === 'ISSUED' && this.seatInOrder.orderSeats.length > 1" class="success-order-pay__tickets-info-title">Мы отправили билеты и квитанцию об оплате на эл. почту {{seatInOrder.email}}</p>
        
        <p v-if="isTicket && !isParking && !isSeasonTicket && this.seatInOrder.orderSeats.length > 1" class="success-order-pay__tickets-info-subtitle-16">
          Билеты также хранятся в вашем <a class="success-order-pay__section-link" href="/orders">личном кабинете.</a> 
        </p>
        <p v-if="!isTicket && isParking && !isSeasonTicket && this.seatInOrder.orderSeats.length > 1" class="success-order-pay__tickets-info-subtitle-16">
          Билеты также хранятся в вашем <a class="success-order-pay__section-link" href="/orders">личном кабинете.</a> 
        </p>
        <p v-if="!isTicket && !isParking && isSeasonTicket && this.seatInOrder.orderSeats.length > 1" class="success-order-pay__tickets-info-subtitle-16">
          Абонементы также хранятся в вашем <a class="success-order-pay__section-link" href="/orders">личном кабинете.</a> 
        </p>
        <p v-if="(isTicket || isParking) && isSeasonTicket && this.seatInOrder.orderSeats.length > 1" class="success-order-pay__tickets-info-subtitle-16">
          Билеты также хранятся в вашем <a class="success-order-pay__section-link" href="/orders">личном кабинете.</a> 
        </p>
      </div>
      <div v-if="seatInOrder.payStatus === 'PAID'" class="success-order-pay__order-info">
        <!-- <p class="success-order-pay__order-info-title">Стоимость заказа: {{seatInOrder.orderPrice}} ₽ .</p>
        <p class="success-order-pay__order-info-subtitle">
          Общее количество бонусов можно посмотреть в <a class="success-order-pay__section-link" href="https://lk.hcsochi.ru/" target="_blank">личном кабинете</a> на сайте.
        </p> -->
        <p v-if="(isTicket || isParking) && !isSeasonTicket && this.seatInOrder.orderSeats && this.seatInOrder.orderSeats.length > 1">Для загрузки билетов просим Вас обновить страницу и нажать на кнопку "Скачать билеты".</p>
        <p v-if="(!isTicket && !isParking) && isSeasonTicket && this.seatInOrder.orderSeats && this.seatInOrder.orderSeats.length > 1">Для загрузки абонементов просим Вас обновить страницу и нажать на кнопку "Скачать билет".</p>
        <p v-if="(isTicket || isParking) && isSeasonTicket && this.seatInOrder.orderSeats && this.seatInOrder.orderSeats.length > 1">Для загрузки билетов просим Вас обновить страницу и нажать на кнопку "Скачать билеты".</p>

        <p v-if="(isTicket || isParking) && !isSeasonTicket && this.seatInOrder.orderSeats && this.seatInOrder.orderSeats.length === 1">Для загрузки билета просим Вас обновить страницу и нажать на кнопку "Скачать билеты".</p>
        <p v-if="(!isTicket && !isParking) && isSeasonTicket && this.seatInOrder.orderSeats && this.seatInOrder.orderSeats.length === 1">Для загрузки абонемента просим Вас обновить страницу и нажать на кнопку "Скачать билеты".</p>

      </div> 
      <div v-if="seatInOrder.payStatus" class="success-order-pay__order-list">
        <p class="success-order-pay__order-list-title">Состав заказа:</p>
        <div v-for="seat in seatsInOrder" :key="seat.id" class="success-order-pay__order-section">
            <p v-if="seat.length === 1 && (seat[0].algorithmSaleId === 510 || seat[0].algorithmSaleId === 516)" class="success-order-pay__order-list-subtitle">
            {{seat.length}} билет на матч {{seat[0].calendarName}} {{ moment(seat[0].calendarDay,'DD.MM.YYYY').locale('ru').format('DD MMMM (dddd)') }} в {{ `${seat[0].calendarTime.substr(0, 5)}` }}</p>
            <p v-if="seat.length > 1 && seat.length < 5 && (seat[0].algorithmSaleId === 510 || seat[0].algorithmSaleId === 516)" class="success-order-pay__order-list-subtitle">
            {{seat.length}} билета на матч {{seat[0].calendarName}} {{ moment(seat[0].calendarDay,'DD.MM.YYYY').locale('ru').format('DD MMMM (dddd)') }} в {{ `${seat[0].calendarTime.substr(0, 5)}` }}</p>
            <p v-if="seat.length >= 5 && (seat[0].algorithmSaleId === 510 || seat[0].algorithmSaleId === 516)" class="success-order-pay__order-list-subtitle">
            {{seat.length}} билетов на матч {{seat[0].calendarName}} {{ moment(seat[0].calendarDay,'DD.MM.YYYY').locale('ru').format('DD MMMM (dddd)') }} в {{ `${seat[0].calendarTime.substr(0, 5)}` }}</p>
            
            <p v-if="(seat[0].algorithmSaleId === 520 || seat[0].algorithmSaleId === 530 || seat[0].algorithmSaleId === 540)" class="success-order-pay__order-list-subtitle">
            {{seat[0].calendarName}}</p>
            
          <div class="success-order-pay__places">
            <p class="success-order-pay__order-list-places">Места: </p>
            <div class="success-order-pay__places-list">
              <ul>
                <li class="success-order-pay__order-list-item" v-for="innerSeat in seat" :key="innerSeat.id">{{innerSeat.name}}</li>
              </ul>
            </div>
          </div>
        </div>  
      </div>  
      <div class="success-order-pay__actions">
        <button v-if="seatInOrder.payStatus === 'PAID' && seatInOrder.status === 'ISSUED'"
          type="button"
          class="yellow-button" style="margin-right: 32px;"
          :class="{'loading': isLoading}"
          :disabled="isLoading"
          @click="printTickets(seatInOrder.orderId, seatInOrder.orderCreateDate)" 
        >
          <span v-if="this.seatInOrder.orderSeats && this.seatInOrder.orderSeats.length > 1" class="success-order-pay__print-tickets-title">Скачать билеты</span>
          <span v-else class="success-order-pay__print-tickets-title">Скачать билет</span>
          <span class="loader"></span>
        </button>
        <router-link :to="{ name: 'main' }">
          <button class="success-order-pay__back-to-site-error" v-if="seatInOrder.payStatus != 'PAID'">
            <span class="success-order-pay__back-to-site-title">Вернуться на главную страницу</span>
          </button>
          <button class="success-order-pay__back-to-site" v-if="seatInOrder.payStatus === 'PAID'">
            <span class="success-order-pay__back-to-site-title">Вернуться на главную страницу</span>
          </button>
        </router-link>
      </div>
    </div>
    <ErrorPopup
      v-if="isErrorPopupShown"
      @closeErrorPopup="closeErrorPopup"
      :errorMessage="errorMessage"
    />
    <AuthorizationModal 
      v-if="isAuthorizationModalShown"
      @closeAuthorizationModal="closeAuthorizationModal" 
    />
  </div>
  <div class="all" v-else style="min-height: 300px; display: flex;justify-content: center;align-items: center;">
    <half-circle-spinner
            :animation-duration="2000"
            :size="60"
            :color="'#FFF'"
          />
  </div>
</template>

<script>
import { orderMapper } from '@/store/modules/order';
import AuthorizationModal from "@/components/header/AuthorizationModal.vue";
import {authorizationMapper} from "@/store/modules/authorization";
import {analyticMapper} from '@/store/modules/analytic';
import { HalfCircleSpinner } from 'epic-spinners';
import ErrorPopup from './ErrorPopup.vue';
import * as $ from 'jquery';
// import * as printJS from 'print-js';

export default {
  name: 'SuccessOrderPay',
  data() {
    return {
      isErrorPopupShown: false,
      errorMessage: '',
      isLoading: false,
      isAuthorizationModalShown: false,
      isTicket: false,
      isSeasonTicket: false,
      isParking: false,
    }
  },
  components: {
    HalfCircleSpinner,
    ErrorPopup,
    AuthorizationModal,
  },
  async mounted() {
    setTimeout(() =>{
     this.getSeatInOrder(this.$route.params.orderId);
     this.pushYaMetrikPurchase(this.seatInOrder);
    },2000);
    //await this.simpleAuthLogout();
   // this.orderId = this.$route.params.orderId;
  },
  computed: {
    ...orderMapper.mapGetters(['seatInOrder', 'printLink']),
    ...authorizationMapper.mapGetters(['userInfo']),
    seatsInOrder() {
      return this.seatInOrder.orderSeats.reduce((result, item) => {
      if (result[item.calendarId]) {
        result[item.calendarId].push(item)
      } else {
        result = {...result, [item.calendarId]: [item]}
      }
      if (item.algorithmSaleId === 510) {
        this.isTicket = true;
      }
      if (item.algorithmSaleId === 516) {
        this.isParking = true;
      }
      if (item.algorithmSaleId === 520 || item.algorithmSaleId === 530 || item.algorithmSaleId === 540) {
        this.isSeasonTicket = true;
      }
      return result;
      }, {})
    },

  },
  methods: {
    ...orderMapper.mapActions(['getSeatInOrder', 'printOrderTickets']),
    ...analyticMapper.mapActions(['pushYaMetrikPurchase']),
    //...authorizationMapper.mapActions(['simpleAuthLogout']),
    isSafari() {
        return Object.prototype.toString.call((window).HTMLElement).indexOf('Constructor') > 0 ||
            navigator.userAgent.toLowerCase().indexOf('safari') !== -1;
    },
    async printTickets(orderId, date) {
      const authorizedUserEmail = this.userInfo.userEmail;

      if (!authorizedUserEmail) {
        const wrapTrash = $('.subheader');
        wrapTrash.addClass('hide');
        this.isAuthorizationModalShown = true;
        return;
      } else {
        this.isLoading = true;
        try {
          const pdf = await this.printOrderTickets(orderId);

          const pdfUrl = URL.createObjectURL(pdf);
          // if (this.isSafari()) {
            const a = document.createElement('a');
            a.title = 'HC Sochi ticket';
            // document.body.appendChild(a);
            // a.style.display = 'none';
            // a.setAttribute('target', '_blank');
            a.href = pdfUrl;
            // a.click();
            // document.body.removeChild(a);
            a.download = 'Order ' + orderId + ' ' + date + '.pdf';
            a.dispatchEvent(new MouseEvent('click'));
          // } else {
          //   setTimeout(() => {
          //       printJS({printable: pdfUrl, type: 'pdf'});
          //     }, 500);
          // }
          this.isLoading = false;
        } catch (error) {
          this.errorMessage = error.message;
          this.isErrorPopupShown = true;
          this.isLoading = false;
        }
      }
    },
    closeErrorPopup(isShowModal) {
      this.isErrorPopupShown = isShowModal;
    },
    closeAuthorizationModal(isShowModal) {
      this.isAuthorizationModalShown = isShowModal;
      const wrapTrash = $('.subheader');
      wrapTrash.removeClass('hide');
    },
  },
}
</script>

<style lang="scss" src="@/styles/SuccessOrderPay.scss">
</style>
