<template>
  <div class="error-page">
    <div class="error-page__wrapper">
      <span class="error-page__title">Страница не найдена</span>
      <p class="error-page__subtitle">Неправильно набран адрес,<br>или такой страницы на сайте больше не существует.</p>
      <a href="/" class="error-page__main-page-button">Перейти на главную страницу</a>
    </div>
</div>
</template>

<script>
export default {
  name: 'ErrorPage',
}
</script>

<style lang="scss" src="@/styles/ErrorPage.scss">
</style>
